import { ChatManager, TokenProvider } from '@pusher/chatkit-client';

const INSTANCE_LOCATOR = process.env.REACT_APP_CHATKIT_INSTANCE_LOCATOR;
const TOKEN_PROVIDER = process.env.REACT_APP_CHATKIT_TOKEN_PROVIDER;

class BachelorChatManager {
  constructor(options) {
    this.chatRoomData = options.chatRoomData;
    this.baseUserId = `${options.chatRoomData.user_id}`; // userId needs to be a string
    this.chatManager = new ChatManager({
      instanceLocator: INSTANCE_LOCATOR,
      userId: this.baseUserId,
      tokenProvider: new TokenProvider({ url: TOKEN_PROVIDER })
    });
    this.connected = false;
    this.shouldIgnoreOnMessageHook = true;
  }

  get isConnected() {
    return this.connected;
  }

  async connect() {
    try {
      this.currentUser = await this.chatManager.connect();
      this.connected = true;
      console.log('Successful connection', this.currentUser); // TODO: remove
      this.setupListeners();
    } catch (err) {
      this.currentUser = null;
      this.connected = false;
      console.log('Error on connection', err); // TODO: remove
    }
  }

  subscribeToRoom(hooks, messageLimit = 100) {
    this.currentUser.subscribeToRoom({
      roomId: this.chatRoomData.room_id,
      hooks,
      messageLimit
    });
  }

  sendMessage(message) {
    if (message.trim().length) {
      this.currentUser.sendMessage({
        text: message,
        roomId: this.chatRoomData.room_id
      });
      // this.saveChatHistory(message.text);
    }
  }

  async setReadCursor(position) {
    try {
      await this.currentUser.setReadCursor({
        roomId: this.chatRoomData.room_id,
        position
      });
      console.log('Successfully set read cursor for message id: ', position);
      return true;
    } catch (err) {
      console.log(`Error setting cursor: ${err}`);
      return false;
    }
  }

  readCursor() {
    const cursor = this.currentUser.readCursor({
      roomId: this.chatRoomData.room_id,
      userId: this.chatRoomData.user_id
    });
    return cursor;
  }

  setupListeners() {
    //    // handle when user is viewing chat room session
    //    window.addeventlistener('focus', () => {
    //      console.log('window was focused');
    //    });
    //
    //    // handle when user is not viewing chat room session
    //    window.addeventlistener('blur', () => {
    //      console.log('window was blurred');
    //    });
  }
}

export default BachelorChatManager;
