import * as types from '../actions/types';
const INITIAL = {
  first: false, // true no sync await
  homescreen: null,
  isError: false,
  isLoading: false
};

const homescreenReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.CLEAR_HOMESCREEN:
      return INITIAL;
    case types.HOME_SCREEN_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.HOME_SCREEN_SUCCEEDED:
      return {
        ...state,
        homescreen: action.data,
        isLoading: false
      };
    case types.HOME_SCREEN_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error,
        isLoading: false
      };
    case types.UPDATE_USER_PARTICIPATION_DATES:
      return {
        ...state,
        homescreen: {
          ...state.homescreen,
          participation: {
            ...state.homescreen.participation,
            ...action.dates
          }
        }
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default homescreenReducer;
