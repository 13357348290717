import React from 'react';
import Popup from '../../components/Popup';
import {
  ButtonComponent,
  TitleComponent,
  MessagesComponent
} from '../../components/ChatFeature/CafeMessagePopup';
import {
  ButtonComponent as MeetingTimeButton,
  MessagesComponent as MeetingTimeMessages
} from '../../components/ChatFeature/MeetingTimePopup';
import { CancelDateMessagesComponent } from '../../components/MatchProfile/ConfirmCancelDatePopup';
import { MessagesComponent as StillUnreadMessages } from '../../components/ChatFeature/StillUnreadPopup';
import { MessagesComponent as CancelMessages } from '../../components/ChatFeature/CancelPopup';
import { MessagesComponent as AfterFivePmMessages } from '../../components/ChatFeature/AfterFivePmPopup';
import { MessagesComponent as GotCancelledMessages } from '../../components/ChatFeature/GotCancelledPopup';
import { TitleComponent as UnavailableTitleComponent } from '../../components/ChatFeature/UnavailableChatPopup';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { path, isEmpty, pathOr } from 'ramda';
import {
  chatRoom as requestChatRoom,
  getMatchDetail as requestMatchDetail,
  getCafe as requestGetCafeSuggestion,
  getChatMessages as requestChatMessages,
  showLoading,
  hideLoading,
  chatHistory,
  cancelDate
} from '../../redux/actions';
import ChatFeature from '../../components/ChatFeature';
import { getPartnerProfileImage, hasChatRoomMessage } from './helpers';
import BachelorChatManager from './bachelorChatManager';
import {
  ChatFeature as ChatFeatureConstants,
  MatchDetails,
  UserGender,
  redirectFB
} from '../../constants/constant';

const POPUP_CAFE_INFORMATION = 1;
const POPUP_MEETING_TIME = 2;
const POPUP_STILL_UNREAD = 3;
const POPUP_CONFIRM_SCHEDULE = 4;
const POPUP_CANCEL = 5;
const POPUP_AFTER_FIVE_PM = 6;
const POPUP_UNAVAILABLE_TIME = 7;
const POPUP_TRANSFER_TICKET = 8;
const POPUP_WANT_CANCEL = 9;
const POPUP_GOT_CANCELLED = 10;
const POPUP_CANCEL_SUCCESS = 11;

const {
  USER_TYPE_BASE_USER,
  USER_TYPE_PARTNER_USER,
  USER_TYPE_CAFE_INFO,
  USER_TYPE_CAFE_SUGGESTION,
  USER_TYPE_CAFE_SUGGESTION_LINK
} = ChatFeatureConstants;

let datingId = null;

class ChatFeatureContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      popupCafeInformationVisible: false,
      popupMeetingTimeVisible: false,
      popupStillUnread: false,
      popupConfirmSchedule: false,
      popupCancel: false,
      popupAfterFivePm: false,
      popupUnavailableTime: false,
      popupTransferTicket: false,
      popupWantCancelPopup: false,
      popupGotCancelled: false,
      popupCancelSuccess: false,
      // CHAT related
      bachelorChatManager: null,
      chatRoomMessages: [],
      partnerData: null,
      baseUserIsPresent: false,
      hasFirstBachelorMessage: false,
      shouldPaginate: false,
      shouldScrollToLatestMessage: false,
      userStatus: false,
      chatRoomIsReady: false
    };

    this.chatFeatureRef = React.createRef();
  }

  componentDidMount = () => {
    // TODO:
    // Check auth
    // If user doesn't login, redirect to login page
    if (!this.props.loggedIn) {
      localStorage.setItem('redirect_to', window.location.pathname);
      this.props.history.push('/login');
      return;
    } else {
      localStorage.setItem('redirect_to', null);
    }

    this.props.requestMatchDetail({}, response => {
      const matchData = response.data;
      const datingDay = this.getCurrentDating(matchData);

      if (datingDay) {
        datingId = datingDay.dating_id;
        const { partner_details, dating_status } = datingDay;

        // If dating_id == null, the chat is not available, then redirect to home screen
        if (!datingId) {
          this.openPopup(POPUP_UNAVAILABLE_TIME);
          return;
        }

        // If a date is not confirmed, the chat is not available, then redirect to home screen
        if (dating_status !== MatchDetails.STATUS_DATING_MATCHED_CONFIRM) {
          this.openPopup(POPUP_UNAVAILABLE_TIME);
          return;
        }

        // TODO:
        // Check available chat time
        // If chat time is not available, redirect to homescreen page
        const current_server_time = matchData.current_server_time;
        const { chat_start_time, chat_end_time } = datingDay;

        if (
          current_server_time < chat_start_time ||
          current_server_time > chat_end_time
        ) {
          this.openPopup(POPUP_UNAVAILABLE_TIME);
          return;
        }

        this.setState({
          partnerData: partner_details
        });

        const chatId = path(['chat_id'], datingDay);
        const roomId = path(['room_id'], datingDay);

        const options = {
          chatRoomData: {
            user_id: chatId,
            room_id: roomId
          }
        };
        const bachelorChatManager = new BachelorChatManager(options);
        this.setState(
          {
            bachelorChatManager
          },
          () => {
            this.handleChatManager();
          }
        );
        return;
      } else {
        this.openPopup(POPUP_UNAVAILABLE_TIME);
      }
    });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.state.userStatus !== nextState.userStatus) {
      return false;
    }
    return true;
  };

  checkBeforeFivePm = currentServerTime => {
    const currentTime = new Date(currentServerTime.replace(' ', 'T'));
    const fivePMTime =
      currentTime.getFullYear() +
      '-' +
      (currentTime.getMonth() + 1) +
      '-' +
      currentTime.getDate() +
      ' ' +
      '17:00:00';
    return currentServerTime < fivePMTime;
  };

  getCurrentDating = matchData => {
    if (!matchData) {
      return null;
    }

    const currentPath = this.props.location.pathname;

    if (currentPath.indexOf('chat/saturday') > -1) {
      return path(['saturday'], matchData);
    } else if (currentPath.indexOf('chat/sunday') > -1) {
      return path(['sunday'], matchData);
    }

    return null;
  };

  initFirstBachelorMessage = datingInformation => {
    if (!datingInformation && this.state.hasFirstBachelorMessage) return;
    const { t } = this.props;

    const cafeInfoMessage = {
      userType: USER_TYPE_CAFE_INFO,
      data: {
        datingTime: pathOr('', ['time'], datingInformation),
        cafeName: pathOr('', ['cafe_details', 'name'], datingInformation),
        cafePhone: pathOr(
          '',
          ['cafe_details', 'display_phone'],
          datingInformation
        ),
        yelpUrl: pathOr('', ['cafe_details', 'yelp_url'], datingInformation),
        stationName: pathOr(
          [''],
          ['cafe_details', 'station'],
          datingInformation
        )[0]
      }
    };

    const cafeSuggestionLinkMessage = {
      userType: USER_TYPE_CAFE_SUGGESTION_LINK,
      data: {
        text: t('chat-link-cafe-suggestion'),
        onChatLinkClick: this.onCafeSuggestionLinkClick
      }
    };

    this.setState(prevState => {
      return {
        hasFirstBachelorMessage: true,
        chatRoomMessages: [
          cafeInfoMessage,
          cafeSuggestionLinkMessage,
          ...prevState.chatRoomMessages
        ]
      };
    });
  };

  initCafeInfoMessage = datingInformation => {
    if (!datingInformation) return;

    const cafeInfoMessage = {
      userType: USER_TYPE_CAFE_INFO,
      data: {
        datingTime: pathOr('', ['time'], datingInformation),
        cafeName: pathOr('', ['cafe_details', 'name'], datingInformation),
        cafePhone: pathOr(
          '',
          ['cafe_details', 'display_phone'],
          datingInformation
        )
      }
    };

    this.setState(prevState => {
      return {
        chatRoomMessages: [...prevState.chatRoomMessages, cafeInfoMessage]
      };
    });
  };

  initCafeSuggestionMessage = data => {
    if (!data) return;

    const cafeSuggestionMessage = {
      userType: USER_TYPE_CAFE_SUGGESTION,
      data: {
        text1: data.text1,
        text2: data.text2,
        cafeList: data.cafeData
      }
    };

    this.setState(prevState => {
      return {
        shouldScrollToLatestMessage: true,
        chatRoomMessages: [...prevState.chatRoomMessages, cafeSuggestionMessage]
      };
    });
  };

  initCafeSuggestionLink = () => {
    const { t } = this.props;

    const cafeSuggestionLinkMessage = {
      userType: USER_TYPE_CAFE_SUGGESTION_LINK,
      data: {
        text: t('chat-link-cafe-suggestion'),
        onChatLinkClick: this.onCafeSuggestionLinkClick
      }
    };

    this.setState(prevState => {
      return {
        shouldScrollToLatestMessage: true,
        chatRoomMessages: [
          ...prevState.chatRoomMessages,
          cafeSuggestionLinkMessage
        ]
      };
    });
  };

  onCafeSuggestionLinkClick = () => {
    if (!datingId) return;

    const params = {
      query: {
        dating_id: datingId
      }
    };

    this.props.requestGetCafeSuggestion(params, response => {
      if (!isEmpty(response)) {
        this.initCafeSuggestionMessage(response.data);
      }
    });
  };

  onUnreadClick = () => {
    this.openPopup(POPUP_STILL_UNREAD);
  };

  closeAvailableTimePopup = () => {
    this.props.history.push('/');
  };

  handleChatManager = async () => {
    const LIMIT = '20';
    const { bachelorChatManager } = this.state;
    if (!bachelorChatManager.isConnected) {
      this.props.showLoading();
      await bachelorChatManager.connect();
      const params = {
        query: {
          room_id: bachelorChatManager.chatRoomData.room_id,
          direction: 'older',
          limit: LIMIT
        }
      };
      const response = await new Promise(resolve => {
        this.props.requestChatMessages(params, resolve);
      });

      bachelorChatManager.subscribeToRoom({
        onMessage: this.onChatMessage,
        onPresenceChanged: this.onPresenceChanged,
        onNewReadCursor: this.onNewReadCursor
      });

      if (path(['location', 'state', 'fromMyTime'], this.props) !== undefined) {
        this.props.history.replace({
          ...this.props.history.location,
          state: null
        });
        this.openPopup(POPUP_MEETING_TIME);
      }

      const chatMessages = response.data.sort((a, b) => a.id - b.id); // sort by id lowest to highest (oldest to newest)

      if (chatMessages.length < LIMIT || chatMessages.length === 0) {
        await new Promise(resolve => {
          this.setState({ shouldPaginate: false }, resolve);
        });

        const datingDay = this.getCurrentDating(this.props.matchData);
        this.initFirstBachelorMessage(datingDay);
      }

      this.setState(
        prevState => {
          return {
            chatRoomIsReady: true,
            shouldScrollToLatestMessage: true,
            shouldPaginate: this.state.hasFirstBachelorMessage ? false : true,
            currentOldestMessageId:
              chatMessages.length === 0 ? '' : chatMessages[0].id,
            chatRoomMessages: [
              ...prevState.chatRoomMessages,
              ...chatMessages.map(msg => {
                return {
                  ...msg,
                  avatar:
                    `${msg.user_id}` === bachelorChatManager.baseUserId
                      ? null
                      : getPartnerProfileImage(this.state.partnerData),
                  userType:
                    `${msg.user_id}` === bachelorChatManager.baseUserId
                      ? USER_TYPE_BASE_USER
                      : USER_TYPE_PARTNER_USER,
                  onUnreadClick: this.onUnreadClick,
                  shouldBlurIcon: !!path(
                    ['profileImage'],
                    this.state.partnerData
                  )
                };
              })
            ]
          };
        },
        () => {
          this.props.hideLoading();
        }
      );
      this.setupListeners();
    }
  };

  onNewReadCursor = data => {
    console.log('newReadCursor', data);
    const updatedMessages = this.state.chatRoomMessages.map(message => {
      if (message.id <= data.position) {
        return { ...message, read: true };
      }
      return message;
    });
    this.setState({ chatRoomMessages: updatedMessages });
  };

  setupListeners = () => {
    // handle when user is viewing chat room session
    window.addEventListener('focus', async () => {
      console.log('window was focused');
      const { chatRoomMessages, bachelorChatManager } = this.state;
      const lastMessageIndex =
        chatRoomMessages.length && chatRoomMessages.length - 1;
      const latestMessage = chatRoomMessages[lastMessageIndex] || {};
      const latestMessageId = latestMessage.id;
      if (!latestMessageId) {
        return;
      }
      await bachelorChatManager.setReadCursor(latestMessageId); // set cursor position to latest message
    });

    // handle when user is not viewing chat room session
    window.addEventListener('blur', () => {
      console.log('window was blurred');
    });
  };

  onChatMessage = async data => {
    const { id, senderId, text, createdAt } = data;
    const { bachelorChatManager, chatRoomMessages } = this.state;
    if (hasChatRoomMessage(id, chatRoomMessages)) {
      return;
    }

    const dateTime = new Date(createdAt.replace(' ', 'T')); // TODO: format 12 hour timestamp
    const incomingMessage = {
      id,
      text,
      createdAt: `${dateTime.getHours()}:${dateTime.getMinutes()}`,
      avatar:
        senderId === bachelorChatManager.baseUserId
          ? null
          : getPartnerProfileImage(this.state.partnerData),
      userType:
        senderId === bachelorChatManager.baseUserId
          ? USER_TYPE_BASE_USER
          : USER_TYPE_PARTNER_USER,
      onUnreadClick: this.onUnreadClick,
      shouldBlurIcon: !!path(['profileImage'], this.state.partnerData)
    };

    if (document.hasFocus()) {
      await this.state.bachelorChatManager.setReadCursor(data.id); // set cursor position to latest message
    }

    this.setState(prevState => {
      return {
        chatRoomMessages: [...prevState.chatRoomMessages, incomingMessage],
        shouldScrollToLatestMessage: true
      };
    });
  };

  onPresenceChanged = (state, user) => {
    this.setState({
      userStatus: state.current === 'online'
    });
  };

  saveChatHistory = message => {
    const datingDay = this.getCurrentDating(this.props.matchData);
    if (!datingDay) return;
    const partnerId = path(['partner_details', 'partner_id'], datingDay);
    if (!partnerId) return;
    if (!message) return;

    const { bachelorChatManager } = this.state;

    const params = {
      query: {
        dating_id: datingDay.dating_id,
        room_id: bachelorChatManager.chatRoomData.room_id,
        partner_id: partnerId,
        partner_status: this.state.userStatus,
        text: message
      }
    };

    this.props.chatHistory(params);
  };

  fetchMoreMessages = async () => {
    const LIMIT = '20';
    const {
      bachelorChatManager,
      currentOldestMessageId,
      shouldPaginate
    } = this.state;

    if (!bachelorChatManager.isConnected || !shouldPaginate) {
      return;
    }
    const params = {
      query: {
        room_id: bachelorChatManager.chatRoomData.room_id,
        message_id: currentOldestMessageId,
        direction: 'older',
        limit: LIMIT
      }
    };
    this.props.showLoading();
    const response = await new Promise(resolve => {
      this.props.requestChatMessages(params, resolve);
    });

    const newMessages = response.data.sort((a, b) => a.id - b.id); // sort by id lowest to highest (oldest to newest)

    this.setState(
      prevState => {
        return {
          shouldScrollToLatestMessage: false,
          currentOldestMessageId:
            newMessages.length === 0 ? '' : newMessages[0].id,
          chatRoomMessages: [
            // TODO: Tidy this up / Make this cleaner
            ...newMessages.map(msg => {
              return {
                ...msg,
                avatar:
                  `${msg.user_id}` === bachelorChatManager.baseUserId
                    ? null
                    : getPartnerProfileImage(this.state.partnerData),
                userType:
                  `${msg.user_id}` === bachelorChatManager.baseUserId
                    ? USER_TYPE_BASE_USER
                    : USER_TYPE_PARTNER_USER,
                onUnreadClick: this.onUnreadClick,
                shouldBlurIcon: !!path(['profileImage'], this.state.partnerData)
              };
            }),
            ...prevState.chatRoomMessages
          ]
        };
      },
      () => {
        this.props.hideLoading();
      }
    );

    if (newMessages.length < LIMIT || newMessages.length === 0) {
      const datingDay = this.getCurrentDating(this.props.matchData);
      this.setState({ shouldPaginate: false }, () => {
        this.initFirstBachelorMessage(datingDay);
      });
    }
  };

  onConfirmCancelDate = () => {
    const { matchData } = this.props;
    if (!matchData || isEmpty(matchData)) {
      return null;
    }

    const datingDay = this.getCurrentDating(matchData);
    const strBody = 'dating_id=' + datingDay.dating_id;
    this.requestCancelDateAPI(strBody);
  };

  requestCancelDateAPI = body => {
    this.props.cancelDate(
      {
        body
      },
      response => {
        this.openPopup(POPUP_CANCEL_SUCCESS);
      }
    );
  };

  onCancelSuccess = () => {
    this.props.history.push('/matchprofile');
  };

  renderCafeInformationPopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupCafeInformationVisible}
        TitleComponent={
          <TitleComponent
            title1={t('popup-cafe-title-1')}
            title2={t('popup-cafe-title-2')}
          />
        }
        MessagesComponent={
          <MessagesComponent message={t('popup-cafe-message')} />
        }
        ButtonComponent={
          <ButtonComponent
            cafeButtonTitle={t('popup-cafe-button')}
            note={t('popup-cafe-note')}
            onCafeButtonClick={this.onCafeButtonClick}
          />
        }
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderMeetingTimePopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupMeetingTimeVisible}
        title={t('popup-meeting-time-title')}
        MessagesComponent={
          <MeetingTimeMessages
            messages={[
              t('popup-meeting-time-message-1'),
              t('popup-meeting-time-message-2'),
              t('popup-meeting-time-message-3')
            ]}
          />
        }
        ButtonComponent={
          <MeetingTimeButton
            note={t('popup-meeting-time-text-link')}
            onTextLinkClick={this.onMeetingTimeLinkClick}
          />
        }
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderStillUnreadPopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupStillUnread}
        title={t('popup-still-unread-title')}
        MessagesComponent={
          <StillUnreadMessages
            message1={t('popup-still-unread-message-1')}
            message2={t('popup-still-unread-message-2')}
            message3={t('popup-still-unread-message-3')}
            linkText={t('popup-still-unread-link-text')}
            contactMessage={t('popup-still-unread-message-contact')}
            onContactLinkClick={this.onContactLinkClick}
          />
        }
        ButtonComponent={<></>}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderConfirmSchedulePopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupConfirmSchedule}
        title={t('popup-confirm-schedule-title')}
        messages={[
          t('popup-confirm-schedule-message-1'),
          t('popup-confirm-schedule-message-2')
        ]}
        positiveButtonTitle={t('popup-confirm-schedule-button-participate')}
        negativeButtonTitle={t('popup-confirm-schedule-button-cancel')}
        onPositiveClicked={this.onParticipateButtonClick}
        onNegativeClicked={this.onCancelButtonClick}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderCancelNoticePopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupCancel}
        title={t('popup-cancel-title')}
        MessagesComponent={<CancelMessages />}
        ButtonComponent={<></>}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderAfterFivePmPopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupAfterFivePm}
        title={t('popup-after-five-pm-title')}
        MessagesComponent={<AfterFivePmMessages />}
        positiveButtonTitle={t('popup-after-five-pm-button-ok')}
        negativeButtonTitle={t('popup-after-five-pm-button-cancel')}
        onPositiveClicked={this.onStillOkAfterFivePmClick}
        onNegativeClicked={this.onCancelAfterFivePmClick}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderUnavailableTimePopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupUnavailableTime}
        TitleComponent={
          <UnavailableTitleComponent
            title1={t('popup-chat-unavailable-1')}
            title2={t('popup-chat-unavailable-2')}
          />
        }
        okButtonTitle={t('btn-text-ok')}
        MessagesComponent={<></>}
        onOkClicked={this.closeAvailableTimePopup}
        onCancelClicked={this.closeAvailableTimePopup}
      />
    );
  };

  renderTransferTicketPopup = () => {
    const { t } = this.props;
    return (
      <Popup
        visible={this.state.popupTransferTicket}
        title={t('popup-transfer-title')}
        messages={[t('popup-transfer-content')]}
        positiveButtonTitle={t('popup-transfer-positive-btn')}
        negativeButtonTitle={t('popup-transfer-negative-btn')}
        onPositiveClicked={() => {
          window.open('https://www.bachelorapp.net/re-matching');
          this.closePopup();
        }}
        onNegativeClicked={() => {
          if (this.props.userGender === UserGender.MALE) {
            window.open('https://www.bachelorapp.net/dating-ticket');
          } else {
            window.open('https://www.bachelorapp.net/bachelor-ticket');
          }
          this.closePopup();
        }}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderWantCancelPopup = () => {
    const { t } = this.props;
    /**
     * "popup-want-cancel-title": "Are you sure？",
  "popup-want-cancel-content-1": "Cancellation fee",
  "popup-want-cancel-content-2": "3,000 yen",
  "popup-want-cancel-content-3": "You'll not able to see the dating detail after you cancel it.",
  "popup-want-cancel-positive-btn": "Yes",
  "popup-want-cancel-negative-btn": "Back"
     */
    return (
      <Popup
        visible={this.state.popupWantCancelPopup}
        title={t('popup-want-cancel-title')}
        MessagesComponent={
          <CancelDateMessagesComponent
            message1={t('popup-want-cancel-content-1')}
            message2={t('popup-want-cancel-content-2')}
            message3={t('popup-want-cancel-content-3')}
          />
        }
        positiveButtonTitle={t('popup-want-cancel-positive-btn')}
        negativeButtonTitle={t('popup-want-cancel-negative-btn')}
        onPositiveClicked={this.onConfirmCancelDate}
        onNegativeClicked={this.closePopup}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderGotCancelledPopup = () => {
    const { t } = this.props;

    const userPlanType = pathOr(
      'Free',
      ['user_plan_type'],
      this.props.matchData
    );

    const maleCondition = pathOr(
      false,
      ['is_last_week_trial_dating_none'],
      this.props.matchData
    );

    let isTrialTerm = false;
    if (this.props.userGender === UserGender.MALE) {
      isTrialTerm = maleCondition;
    } else {
      isTrialTerm = false;
    }

    return (
      <Popup
        visible={this.state.popupGotCancelled}
        title={t('got-cancelled-popup-title')}
        MessagesComponent={
          <GotCancelledMessages
            t={t}
            isMale={this.props.userGender === UserGender.MALE}
            isTrialTerm={isTrialTerm}
          />
        }
        ButtonComponent={<></>}
        onCancelClicked={this.closePopup}
      />
    );
  };

  renderCancelSuccessPopup() {
    const { t } = this.props;

    return (
      <Popup
        visible={this.state.popupCancelSuccess}
        title={t('popup-cancel-success-title')}
        okButtonTitle={t('popup-btn-cancel')}
        onOkClicked={this.onCancelSuccess}
        disableClose
      />
    );
  }

  openPopup = popupId => {
    this.setState({
      popupCafeInformationVisible: popupId === POPUP_CAFE_INFORMATION,
      popupMeetingTimeVisible: popupId === POPUP_MEETING_TIME,
      popupStillUnread: popupId === POPUP_STILL_UNREAD,
      popupConfirmSchedule: popupId === POPUP_CONFIRM_SCHEDULE,
      popupCancel: popupId === POPUP_CANCEL,
      popupAfterFivePm: popupId === POPUP_AFTER_FIVE_PM,
      popupUnavailableTime: popupId === POPUP_UNAVAILABLE_TIME,
      popupTransferTicket: popupId === POPUP_TRANSFER_TICKET,
      popupWantCancelPopup: popupId === POPUP_WANT_CANCEL,
      popupGotCancelled: popupId === POPUP_GOT_CANCELLED,
      popupCancelSuccess: popupId === POPUP_CANCEL_SUCCESS
    });
  };

  closePopup = () => {
    this.setState({
      popupCafeInformationVisible: false,
      popupMeetingTimeVisible: false,
      popupStillUnread: false,
      popupConfirmSchedule: false,
      popupCancel: false,
      popupAfterFivePm: false,
      popupUnavailableTime: false,
      popupTransferTicket: false,
      popupWantCancelPopup: false,
      popupGotCancelled: false,
      popupCancelSuccess: false
    });
  };

  onCafeButtonClick = () => {
    console.log('onCafeButtonClick');
  };

  onMeetingTimeLinkClick = () => {
    this.openPopup(POPUP_STILL_UNREAD);
  };

  onContactLinkClick = () => {
    console.log('onContactLinkClick');
  };

  onParticipateButtonClick = () => {
    this.closePopup();
  };

  onCancelButtonClick = () => {
    this.openPopup(POPUP_CANCEL);
  };

  onStillOkAfterFivePmClick = () => {
    this.closePopup();
  };

  onCancelAfterFivePmClick = () => {
    this.openPopup(POPUP_CONFIRM_SCHEDULE);
  };

  openMeetingTimePopup = () => {
    this.openPopup(POPUP_MEETING_TIME);
  };

  openTransferTicketPopup = () => {
    this.openPopup(POPUP_GOT_CANCELLED);
  };
  openWantCancelPopup = () => {
    this.openPopup(POPUP_WANT_CANCEL);
  };

  onTransferTicketClick = () => {
    window.open('https://www.bachelorapp.net/contact');
    this.closePopup();
  };

  onUserAvatarClick = () => {
    const currentPath = this.props.location.pathname;

    let isSaturday = false;

    // Check saturday or sunday
    if (currentPath.indexOf('chat/saturday') > -1) {
      isSaturday = true;
    }

    this.props.history.push({
      pathname: '/partnerdetail' + (isSaturday ? '/saturday' : '/sunday')
    });
  };

  onBackButtonClick = () => {
    this.props.history.push('/matchprofile');
  };

  render() {
    return (
      <>
        <ChatFeature
          chatRoomIsReady={this.state.chatRoomIsReady}
          partnerProfileImage={getPartnerProfileImage(this.state.partnerData)}
          fetchMoreMessages={this.fetchMoreMessages}
          partnerData={this.state.partnerData}
          chatRoomMessages={this.state.chatRoomMessages}
          bachelorChatManager={this.state.bachelorChatManager}
          meetingTimePopup={this.openMeetingTimePopup}
          wantCancelPopup={this.openWantCancelPopup}
          transferTicketPopup={this.openTransferTicketPopup}
          textAgeS={this.props.t('ageS')}
          linkTextChat={this.props.t('chat-meeting-time')}
          linkTextChat1={this.props.t('bottom-text-link-1')}
          linkTextChat2={this.props.t('bottom-text-link-2')}
          linkTextChat3={this.props.t('bottom-text-link-3')}
          shouldScrollToLatestMessage={this.state.shouldScrollToLatestMessage}
          saveChatHistory={this.saveChatHistory}
          onUserAvatarClick={this.onUserAvatarClick}
          onBackButtonClick={this.onBackButtonClick}
        />
        {this.renderCafeInformationPopup()}
        {this.renderMeetingTimePopup()}
        {this.renderStillUnreadPopup()}
        {this.renderConfirmSchedulePopup()}
        {this.renderCancelNoticePopup()}
        {this.renderAfterFivePmPopup()}
        {this.renderUnavailableTimePopup()}
        {this.renderTransferTicketPopup()}
        {this.renderWantCancelPopup()}
        {this.renderGotCancelledPopup()}
        {this.renderCancelSuccessPopup()}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    chatRoomData: state.chatRoom.chatRoomData,
    matchData: state.match.dataMatch,
    loggedIn: state.auth.loggedIn,
    userGender: state.auth.userdata.gender
  };
};

const mapDispatchToProps = {
  requestChatRoom,
  requestMatchDetail,
  requestGetCafeSuggestion,
  requestChatMessages,
  showLoading,
  hideLoading,
  chatHistory,
  cancelDate
};

export default compose(
  withNamespaces('chat', { wait: true }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ChatFeatureContainer);
