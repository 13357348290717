import React from 'react';
import { pathOr } from 'ramda';
import Popup from '../../components/Popup';
import { compose } from 'recompose';
import { connect } from 'react-redux';

class ErrorPopup extends React.PureComponent {
  handleCancel = () => {
    const { hideError } = this.props;
    if (hideError) {
      hideError();
    }
  };

  render() {
    const { message, error } = this.props;

    const errorCode = pathOr(-1, ['response', 'status'], error);

    // Don't show popup unauthorzied
    if (errorCode === 401) {
      return null;
    }

    if (!message) {
      return null;
    }

    return (
      <Popup
        visible={true}
        title={message}
        onCancelClicked={this.handleCancel}
        MessagesComponent={<></>}
        ButtonComponent={<></>}
      />
    );
  }
}

ErrorPopup.propTypes = {};

ErrorPopup.defaultProps = {};

const mapStateToProps = state => {
  let errorMessage = '';

  errorMessage = state.auth.isError
    ? pathOr('', ['auth', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.auth.error
    };
  }

  errorMessage = state.homescreen.isError
    ? pathOr('', ['homescreen', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.homescreen.error
    };
  }

  errorMessage = state.match.isError
    ? pathOr('', ['match', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.match.error
    };
  }

  errorMessage = state.popups.isError
    ? pathOr('', ['popups', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.popups.error
    };
  }

  errorMessage = state.userProfile.isError
    ? pathOr('', ['userProfile', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.userProfile.error
    };
  }

  errorMessage = state.chatRoom.isError
    ? pathOr('', ['chatRoom', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.chatRoom.error
    };
  }

  errorMessage = state.cafe.isError
    ? pathOr('', ['cafe', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.cafe.error
    };
  }

  errorMessage = state.chatMessages.isError
    ? pathOr('', ['chatMessages', 'error', 'message'], state)
    : '';
  if (errorMessage) {
    return {
      message: errorMessage,
      error: state.chatMessages.error
    };
  }

  return {
    message: '',
    error: null
  };
};

export default compose(
  connect(
    mapStateToProps,
    {}
  )
)(ErrorPopup);
