import React, { Component } from 'react';
import styles from './index.module.css';

import PartnerTextMessage from '@components/ChatFeature/PartnerTextMessage';
import UserTextMessage from '@components/ChatFeature/UserTextMessage';
import CafeInformationMessage from '@components/ChatFeature/CafeInformationMessage';
import CafeSuggestionMessage from '@components/ChatFeature/CafeSuggestionMessage';
import ChatLink from '@components/ChatFeature/ChatLink';
import { ChatFeature as ChatFeatureConstants } from '@constants/constant';

const {
  USER_TYPE_BASE_USER,
  USER_TYPE_PARTNER_USER,
  USER_TYPE_CAFE_INFO,
  USER_TYPE_CAFE_SUGGESTION,
  USER_TYPE_CAFE_SUGGESTION_LINK
} = ChatFeatureConstants;
//
// Shows the interaction of a chat room session.
// -----------------------
class ChatRoom extends Component {
  constructor(props) {
    super(props);
    this.scrollToRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToLatestMessage();
  }

  componentDidUpdate() {
    if (this.props.shouldScrollToLatestMessage) {
      this.scrollToLatestMessage();
    }
  }

  scrollToLatestMessage() {
    this.scrollToRef.current.scrollIntoView();
  }

  handleOnScroll = event => {
    if (event.target.scrollTop === 0) {
      this.props.fetchMoreMessages();
    }
  };

  render() {
    return (
      <div className={styles.chatRoom} onScroll={this.handleOnScroll}>
        {this.props.messages.map(message => {
          switch (message.userType) {
            case USER_TYPE_BASE_USER:
              return (
                <UserTextMessage
                  key={message.id}
                  message={message.text}
                  read={message.read}
                  onUnreadClick={message.onUnreadClick}
                />
              );
            case USER_TYPE_PARTNER_USER:
              return (
                <PartnerTextMessage
                  key={message.id}
                  avatar={message.avatar}
                  message={message.text}
                  shouldBlurIcon={false}
                />
              );
            case USER_TYPE_CAFE_INFO:
              const cafeInfo = message.data;
              return <CafeInformationMessage cafeInfo={cafeInfo} />;
            case USER_TYPE_CAFE_SUGGESTION:
              const cafeSuggestion = message.data;
              return <CafeSuggestionMessage cafeSuggestion={cafeSuggestion} />;
            case USER_TYPE_CAFE_SUGGESTION_LINK:
              const { text, onChatLinkClick } = message.data;
              return <ChatLink text={text} onChatLinkClick={onChatLinkClick} />;
            default:
              return null;
          }
        })}
        <span key="scroll" className={styles.scrollTo} ref={this.scrollToRef} />
      </div>
    );
  }
}
export default ChatRoom;
