import React from 'react';
import { Trans } from 'react-i18next';
import styles from './index.module.css';

const MessageComponent = ({
  message1,
  message2,
  message3,
  linkText,
  contactMessage,
  onContactLinkClick
}) => (
  <div className={styles.messageContainer}>
    <ul>
      <li className={styles.message}>{message1}</li>
      <li className={styles.message}>{message2}</li>
      <Trans
        className={styles.message}
        parent="li"
        values={{ linkText }}
        i18nKey="chat:popup-still-unread-message-3"
      >
        <a
          href="https://www.bachelorapp.net/ccl-fee"
          target="_blank"
          rel="noopener noreferrer"
        >
          {linkText}
        </a>
      </Trans>
    </ul>
  </div>
);

export default MessageComponent;
