import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';
import qs from 'query-string';

function* getPopup(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.GET_POPUP, API.POST, action.params);
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({ type: types.POPUPS_REQUEST_SUCCEEDED, data: response.data });
    } else if (response.status === ResponseStatus.FAILURE) {
      console.error('SAGA:', response.message);
      yield put({ type: types.POPUPS_REQUEST_FAILED, error: response });
    } else {
      yield put({ type: types.POPUPS_REQUEST_FAILED, error: response });
    }
  } catch (error) {
    yield put({ type: types.POPUPS_REQUEST_FAILED, error });
  }
}

export function* watchGetPopupsData() {
  yield takeLatest(types.POPUPS_REQUESTED, getPopup);
}

function* deletePopup(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.DELETE_POPUP,
      API.POST,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      const parsedRequestBody = qs.parse(action.params.body);
      yield put({
        type: types.DELETE_POPUP_REQUEST_SUCCEEDED,
        popup_id: parsedRequestBody.popup_id
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      console.error('SAGA:', response.message);
      yield put({ type: types.DELETE_POPUP_REQUEST_FAILED, error: response });
    } else {
      yield put({ type: types.DELETE_POPUP_REQUEST_FAILED, error: response });
    }
  } catch (error) {
    yield put({ type: types.DELETE_POPUP_REQUEST_FAILED, error });
  }
}

export function* watchDeletePopupData() {
  yield takeLatest(types.DELETE_POPUP_REQUESTED, deletePopup);
}
