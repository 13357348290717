import * as types from '../actions/types';
import { isEmpty } from 'ramda';

const initialState = {
  isLoading: false,
  dataMatch: {},
  isError: false,
  isRefresh: false,
  expiredAt: null
};

const matchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CURRENT_USER_RESET_MATCH_DATA:
      return {
        ...state,
        expiredAt: null,
        dataMatch: {}
      };
    case types.CURRENT_USER_GET_MATCH_DATA_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null
      };
    case types.CURRENT_USER_GET_MATCH_DATA_SUCCEEDED:
      const expiredTime = new Date();
      expiredTime.setMinutes(expiredTime.getMinutes() + 10); // Set Expired time match data in 10 mins

      return {
        ...state,
        isLoading: false,
        isError: false,
        dataMatch: action.data || {},
        error: null,
        expiredAt: expiredTime.toString()
      };
    case types.CURRENT_USER_GET_MATCH_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        dataMatch: {},
        isError: true,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default matchReducer;
