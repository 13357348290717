import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  useCoupon: null,
  isError: false,
  isRefresh: false
};

const useCouponReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USE_COUPON_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.USE_COUPON_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        useCoupon: action.data,
        error: null
      };
    case types.USE_COUPON_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        useCoupon: null,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default useCouponReducer;
