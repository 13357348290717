import React from 'react';
import styles from './index.module.css';

const MessageComponent = ({ t, isMale, isTrialTerm }) => {
  const link = isTrialTerm
    ? 'https://www.bachelorapp.net/etd-free'
    : isMale
    ? 'https://www.bachelorapp.net/dating-ticket'
    : 'https://www.bachelorapp.net/bachelor-ticket';

  const text = isTrialTerm
    ? t('extend-trial-term')
    : t('get-coupon-text-link', {
        couponName: isMale ? t('bottom-text-male') : t('bottom-text-female')
      });

  return (
    <div className={styles.messageContainer}>
      <div className={styles.message}>{t('got-cancelled-popup-line-1')}</div>
      <ul className={styles.list}>
        <li className={styles.textLink}>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {text}
          </a>
        </li>
        <li className={styles.textLink}>
          <a
            href="https://www.bachelorapp.net/re-match"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            {t('find-another-text-link')}
          </a>
        </li>
      </ul>
      <div className={styles.message}>{t('got-cancelled-popup-line-2')}</div>
    </div>
  );
};

export default MessageComponent;
