import React from 'react';
import styles from './index.module.css';
//
// Shows the chat links that appear in gold below messages.
// Hiển thị các liên kết trò chuyện xuất hiện bằng vàng bên dưới tin nhắn.
// -----------------------
const ChatLink = ({ text, onChatLinkClick }) => (
  <span className={styles.chatLink} onClick={onChatLinkClick}>
    <div className={styles.chatLinkText}>{text}</div>
  </span>
);

export default ChatLink;
