import React from 'react';
import { Trans } from 'react-i18next';
import styles from './index.module.css';

const MessagesComponent = () => (
  <Trans parent="div" i18nKey="chat:popup-cancel-message">
    <div className={styles.messageItem}>
      LINEより運営にキャンセルをご連絡ください。
    </div>
    <div className={styles.messageListItem}>*キャンセル料（自動引き落とし）</div>
    <div className={styles.messageListItem}>デート前日まで：2,000円</div>
    <div className={styles.messageListItem}>デート当日まで：3,000円</div>
    <div className={styles.messageListItem}>当日の無断欠席：6,000円</div>
  </Trans>
);

export default MessagesComponent;
