import React, { Component } from 'react';
import { TabBar } from 'antd-mobile';
import { withNamespaces } from 'react-i18next';
import { compose } from 'recompose';

import styles from './index.module.css';

export const BottomTabKeys = {
  PARTICIPATION_TAB: '1',
  MATCH_PROFILE_TAB: '2',
  USER_PROFILE_TAB: '3'
};

export const WhiteListShowTabbar = [
  '/participation-steps',
  '/matchprofile',
  '/profile'
];

class BottomTabBar extends Component {
  state = {
    selectedTab: BottomTabKeys.PARTICIPATION_TAB,
    hidden: false
  };

  componentDidMount() {
    this.checkCurrentPage(window.location);
    this.props.history.listen((location, action) => {
      this.checkCurrentPage(location);
    });
  }

  checkCurrentPage = location => {
    if (WhiteListShowTabbar.indexOf(location.pathname) > -1) {
      this.setState({ hidden: false });
    } else {
      this.setState({ hidden: true });
    }

    if (window.location.pathname.indexOf('/participation-steps') > -1) {
      this.openTab(BottomTabKeys.PARTICIPATION_TAB);
      return;
    }

    if (window.location.pathname.indexOf('/matchprofile') > -1) {
      this.openTab(BottomTabKeys.MATCH_PROFILE_TAB);
      return;
    }

    if (window.location.pathname.indexOf('/profile') > -1) {
      this.openTab(BottomTabKeys.USER_PROFILE_TAB);
      return;
    }
  };

  openTab = tab => {
    this.setState({
      selectedTab: tab,
      hidden: false
    });
  };

  onTabSelected = tab => {
    if (tab === BottomTabKeys.PARTICIPATION_TAB) {
      // Navigate to participation page
      this.props.history.push('/participation-steps');
      return;
    }

    if (tab === BottomTabKeys.MATCH_PROFILE_TAB) {
      // Navigate to match profile page
      this.props.history.push('/matchprofile');
      return;
    }

    if (tab === BottomTabKeys.USER_PROFILE_TAB) {
      // Navigate to my profile page
      this.props.history.push('/profile');
      return;
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div className={styles.tabContainer}>
        <TabBar
          unselectedTintColor="#949494"
          tintColor="#C0A76E"
          barTintColor="#111111"
          hidden={this.state.hidden}
        >
          <TabBar.Item
            icon={<div className={styles.unselectParticipate} />}
            selectedIcon={<div className={styles.selectParticipate} />}
            title={t('tab-participation')}
            key="Participate"
            selected={
              this.state.selectedTab === BottomTabKeys.PARTICIPATION_TAB
            }
            onPress={() => {
              this.setState(
                {
                  selectedTab: BottomTabKeys.PARTICIPATION_TAB
                },
                () => {
                  this.onTabSelected(BottomTabKeys.PARTICIPATION_TAB);
                }
              );
            }}
          />
          <TabBar.Item
            icon={<div className={styles.unselectDating} />}
            selectedIcon={<div className={styles.selectDating} />}
            title={t('tab-matchprofile')}
            key="Dating"
            selected={
              this.state.selectedTab === BottomTabKeys.MATCH_PROFILE_TAB
            }
            onPress={() => {
              this.setState(
                {
                  selectedTab: BottomTabKeys.MATCH_PROFILE_TAB
                },
                () => {
                  this.onTabSelected(BottomTabKeys.MATCH_PROFILE_TAB);
                }
              );
            }}
          />
          <TabBar.Item
            icon={<div className={styles.unselectMyPage} />}
            selectedIcon={<div className={styles.selectMyPage} />}
            title={t('tab-profile')}
            key="Mypage"
            selected={this.state.selectedTab === BottomTabKeys.USER_PROFILE_TAB}
            onPress={() => {
              this.setState(
                {
                  selectedTab: BottomTabKeys.USER_PROFILE_TAB
                },
                () => {
                  this.onTabSelected(BottomTabKeys.USER_PROFILE_TAB);
                }
              );
            }}
          />
        </TabBar>
      </div>
    );
  }
}

export default compose(withNamespaces('bottom-bar', { wait: true }))(
  BottomTabBar
);
