import React, { Component } from 'react';
import Textarea from 'react-textarea-autosize';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
// Used for typing messages during chat.
// Được sử dụng để gõ tin nhắn trong khi trò chuyện.
// -----------------------
class ChatTextInput extends Component {
  state = {
    text: ''
  };

  onTyping = event => {
    this.setState({ text: event.target.value });
  };

  onSendChatMessage = () => {
    this.props.bachelorChatManager.sendMessage(this.state.text);
    this.props.saveChatHistory(this.state.text);
    this.setState({ text: '' });
  };

  render() {
    const { t } = this.props;

    return (
      <span className={styles.chatTextInput}>
        <Textarea
          value={this.state.text}
          onChange={this.onTyping}
          className={styles.textInputBox}
          placeholder={t('text-input-placeholder')}
          maxRows={3}
        />
        <div onClick={this.onSendChatMessage} className={styles.sendButtonText}>
          {t('send-button')}
        </div>
      </span>
    );
  }
}

export default withNamespaces('chat', { wait: true })(ChatTextInput);
