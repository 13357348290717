import React from 'react';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
//
// Shows text status to the left of the base user's chat bubble.
// Hiển thị trạng thái văn bản ở bên trái của bong bóng trò chuyện của người dùng cơ sở.
// -----------------------
const TextUnread = ({ onUnreadClick, t }) => (
  <span className={styles.container} onClick={onUnreadClick}>
    <div className={styles.unread}>{t('chat-message-text-unread')}</div>
    <div className={styles.ellipse} />
    <div className={styles.questionMark}>？</div>
  </span>
);

export default withNamespaces('chat', { wait: true })(TextUnread);
