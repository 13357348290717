import React from 'react';
import NavBarMain from '../NavBar';
import styles from './index.module.css';

import ChatRoom from './ChatRoom';
import ChatTextInput from './ChatTextInput';
import ChatLinkCustom from './ChatLinkCustom';

const ChatFeature = ({
  chatRoomIsReady,
  partnerProfileImage,
  partnerData,
  chatRoomMessages,
  bachelorChatManager,
  meetingTimePopup,
  wantCancelPopup,
  transferTicketPopup,
  linkTextChat,
  linkTextChat1,
  linkTextChat2,
  linkTextChat3,
  fetchMoreMessages,
  shouldScrollToLatestMessage,
  textAgeS,
  saveChatHistory,
  onUserAvatarClick,
  onBackButtonClick
}) => {
  const { profileImage, job, age } = partnerData || {};

  return (
    <>
      <NavBarMain
        customClassName={styles.navBar}
        title={chatRoomIsReady && `${age}${textAgeS}${job}`}
        userAvatar={chatRoomIsReady && partnerProfileImage}
        shouldBlurIcon={false}
        onUserAvatarClick={onUserAvatarClick}
        leftContent={[
          <img
            key={10}
            src={require('../../assets/arrowLeft.png')}
            alt=""
            className={styles.navIcon}
            onClick={onBackButtonClick}
          />
        ]}
      />
      <div className={styles.wrapper}>
        <ChatRoom
          messages={chatRoomMessages}
          bachelorChatManager={bachelorChatManager}
          fetchMoreMessages={fetchMoreMessages}
          shouldScrollToLatestMessage={shouldScrollToLatestMessage}
        />

        {chatRoomIsReady && (
          <>
            <div className={styles.containerFirstBtn}>
              <ChatLinkCustom
                text={linkTextChat1}
                onChatLinkClick={transferTicketPopup}
              />
            </div>

            <div className={styles.horizontalTextLink}>
              <ChatLinkCustom
                customStyle={styles.bottomTextLink1}
                className={styles.bottomTextLink1}
                text={linkTextChat2}
                leftButton
                onChatLinkClick={wantCancelPopup}
              />
              <ChatLinkCustom
                customStyle={styles.bottomTextLink2}
                className={styles.bottomTextLink2}
                text={linkTextChat3}
                rightButton
                onChatLinkClick={meetingTimePopup}
              />
            </div>
          </>
        )}

        <ChatTextInput
          bachelorChatManager={bachelorChatManager}
          saveChatHistory={saveChatHistory}
        />
      </div>
    </>
  );
};

export default ChatFeature;
