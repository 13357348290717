import React from 'react';
import PropTypes from 'prop-types';
import { NavBar } from 'antd-mobile';
import classNames from 'classnames';

import styles from './index.module.css';

class NavBarMain extends React.PureComponent {
  state = {};

  render() {
    const { userAvatar, shouldBlurIcon } = this.props;

    if (userAvatar) {
      return (
        <NavBar
          className={classNames(styles.navBar, this.props.customClassName)}
          leftContent={this.props.leftContent}
          rightContent={this.props.rightContent}
          onLeftClick={this.props.onLeftClick}
        >
          <div className={styles.chatNavTitle}>
            <img
              alt=""
              src={userAvatar}
              className={classNames({
                [styles.navIcon]: true,
                [styles.blur]: shouldBlurIcon
              })}
              onClick={this.props.onUserAvatarClick}
            />
            <div
              className={styles.navNickname}
              onClick={this.props.onUserAvatarClick}
            >
              {this.props.title}
            </div>
          </div>
        </NavBar>
      );
    }

    if (this.props.innerRef) {
      return (
        <div ref={this.props.innerRef}>
          <NavBar
            className={classNames(styles.navBar, this.props.customClassName)}
            leftContent={this.props.leftContent}
            rightContent={this.props.rightContent}
            onLeftClick={this.props.onLeftClick}
          >
            {this.props.title}
          </NavBar>
        </div>
      );
    }

    return (
      <NavBar
        className={classNames(styles.navBar, this.props.customClassName)}
        leftContent={this.props.leftContent}
        rightContent={this.props.rightContent}
        onLeftClick={this.props.onLeftClick}
      >
        {this.props.title}
      </NavBar>
    );
  }
}

NavBarMain.propTypes = {
  title: PropTypes.string,
  rightContent: PropTypes.array,
  leftContent: PropTypes.array,
  icon: PropTypes.element,
  onLeftClick: PropTypes.func,
  onUserAvatarClick: PropTypes.func,
  customClassName: PropTypes.string
};

NavBarMain.defaultProps = {
  title: '',
  rightContent: [],
  leftContent: [],
  icon: null,
  onLeftClick: () => {},
  customClassName: undefined
};

export default NavBarMain;
export { default as BackArrowIcon } from './BackArrowIcon';
