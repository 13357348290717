import React from 'react';
import styles from './index.module.css';
import className from 'classnames';

const ButtonComponent = ({ cafeButtonTitle, note, onCafeButtonClick }) => {
  return (
    <div className={styles.buttonContainer}>
      <div
        className={className(styles.button, styles.buttonOk)}
        onClick={onCafeButtonClick}
      >
        {cafeButtonTitle}
      </div>
      <div className={styles.note}>{note}</div>
    </div>
  );
};

export default ButtonComponent;
