import { connect } from 'react-redux';
import { compose, branch, renderNothing } from 'recompose';
import { any, equals, path } from 'ramda';
import { showError, hideError } from '../../redux/actions';
import ErrorPopup from '../../components/ErrorPopup';
import { ResponseStatus } from '@constants/constant';

const mapStateToProps = state => {
  return {
    // To show the global popup error,
    // just add the reducer's  state here
    showErrorPopup: any(equals(true))([
      state.auth.isError &&
        (state.auth.error &&
          state.auth.error.status !== ResponseStatus.DEACTIVE_USER &&
          state.auth.error.status !== ResponseStatus.CANCEL_USER),
      // state.cancelDate.isError,
      state.homescreen.isError,
      state.match.isError,
      // state.participation.isError,
      state.popups.isError,
      // state.useCoupon.isError,
      state.userProfile.isError,
      state.chatRoom.isError,
      state.cafe.isError,
      state.chatMessages.isError
    ])
  };
};

const ErrorContainer = compose(
  connect(
    mapStateToProps,
    { showError, hideError }
  ),
  branch(({ showErrorPopup }) => !showErrorPopup, renderNothing)
)(ErrorPopup);

export { ErrorContainer as ErrorPopup };
