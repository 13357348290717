import React from 'react';
import styles from './index.module.css';
import TextRead from '../TextRead';
import TextUnread from '../TextUnread';
import Autolinker from 'autolinker';
//
// Shows the base user's text bubble. Appears to the right of screen.
// Hiển thị bong bóng văn bản của người dùng cơ sở. Xuất hiện bên phải màn hình.
// -----------------------
const UserTextMessage = ({ message, read, onUnreadClick }) => {
  const htmlMessage = Autolinker.link(message, {
    stripPrefix: false
  });

  return (
    <span className={styles.userTextMessage}>
      {read ? <TextRead /> : <TextUnread onUnreadClick={onUnreadClick} />}
      <div
        className={styles.chatUserTextBubble}
        dangerouslySetInnerHTML={{ __html: htmlMessage }}
      />
      <div className={styles.chatUserTimestamp}>2:00 PM</div>
    </span>
  );
};

export default UserTextMessage;
