import { compose, mapProps, setDisplayName } from 'recompose';
import React, { Component } from 'react';
import styles from './index.module.css';

class DefaultPopup extends Component {
  onCloseClick = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  onButtonClick = () => {
    if (this.props.onButtonClick) {
      this.props.onButtonClick();
    }
  };

  onLinkClick = () => {
    if (this.props.onLinkClick) {
      this.props.onLinkClick();
    }
  };

  renderMessage = () => {
    if (this.props.renderMessage) {
      return this.props.renderMessage();
    }
    const message = this.props.message || '';
    if (message.length === 0) {
      return null;
    }
    const messages = message.split(/\\n/g);
    const maxLength = messages.length;
    return messages.map((msg, index) => {
      if (msg.length && index + 1 === maxLength) {
        return msg;
      }
      if (msg.length) {
        return (
          <>
            {msg}
            <br />
            <br />
          </>
        );
      }
      return <br />;
    });
  };

  renderButtons = () => {
    const { buttons = [] } = this.props;
    return (
      <div className={styles.buttonContainer}>
        {buttons.map(button => {
          return (
            <div
              key={button.text}
              className={styles.button}
              onClick={this.onButtonClick}
            >
              {button.text}
            </div>
          );
        })}
      </div>
    );
  };

  renderCloseButton = () => {
    return (
      <div className={styles.closeButtonContainer} onClick={this.onCloseClick}>
        <div className={styles.closeButton} />
      </div>
    );
  };

  render() {
    const { title, message, show } = this.props;
    if (!show) {
      return null;
    }
    return (
      <div className={styles.containerOverlay}>
        <div className={styles.wrapper}>
          <div className={styles.header}>{title}</div>
          {message && (
            <div className={styles.content}>{this.renderMessage()}</div>
          )}
          <div className={styles.footer}>{this.renderButtons()}</div>
          {this.renderCloseButton()}
        </div>
      </div>
    );
  }
}

export default compose(
  setDisplayName('DefaultPopup'),
  mapProps(
    ({ popup, onCloseClick, onButtonClick, renderMessage, show = true }) => {
      return {
        buttons: popup.buttons,
        message: popup.message,
        note: popup.note,
        screen: popup.screen,
        title: popup.title,
        type: popup.type,
        onCloseClick,
        onButtonClick,
        renderMessage,
        show
      };
    }
  )
)(DefaultPopup);
