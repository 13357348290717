import React from 'react';
import ReactSVG from 'react-svg';
import loadingSpinner from '../../assets/loading-spinner.svg';
import styles from './index.module.css';

const LoadingSpinner = ({ className }) => (
  <div className={styles.overlay}>
    <ReactSVG className={className || styles.wrapper} src={loadingSpinner} />
  </div>
);

export { LoadingSpinner };
