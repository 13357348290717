import React from 'react';

import './index.css';

class Popup extends React.PureComponent {
  handleCancel = () => {
    const { onCancelClicked } = this.props;
    if (onCancelClicked) {
      onCancelClicked();
    }
  };

  handleOkClicked = () => {
    const { onOkClicked } = this.props;
    if (onOkClicked) {
      onOkClicked();
    }
  };

  handlePositiveClicked = () => {
    const { onPositiveClicked } = this.props;
    if (onPositiveClicked) {
      onPositiveClicked();
    }
  };

  handleNegativeClicked = () => {
    const { onNegativeClicked } = this.props;
    if (onNegativeClicked) {
      onNegativeClicked();
    }
  };

  handleNeutralClicked = () => {
    const { onNeutralClicked } = this.props;
    if (onNeutralClicked) {
      onNeutralClicked();
    }
  };

  renderTitle() {
    const { title, titleClassName, TitleComponent } = this.props;
    if (TitleComponent) {
      return TitleComponent;
    }
    if (title) {
      return (
        <div className="title-container">
          <div className={titleClassName || 'title'}>{title}</div>
        </div>
      );
    }
    return null;
  }

  renderMessages() {
    const {
      messages,
      MessagesComponent,
      messageStyle,
      messageClassName,
      messagesContainerClassName = 'message-container'
    } = this.props;
    if (MessagesComponent) {
      return MessagesComponent;
    }
    if (messages) {
      var messageItemClass = 'message';
      messageItemClass +=
        messages.length === 1 ? ' message-one-line' : ' message-multiple-lines';

      if (messageClassName) {
        messageItemClass = messageClassName;
      }

      return (
        <div className={messagesContainerClassName}>
          {messages.map((element, i) => {
            if (element) {
              return (
                <div key={i} className={messageItemClass} style={messageStyle}>
                  {element}
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    }
    return null;
  }

  renderOkButton() {
    const buttonLabel = this.props.okButtonTitle;
    if (buttonLabel) {
      return (
        <div className="button button-ok" onClick={this.handleOkClicked}>
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderPositiveButton() {
    const buttonLabel = this.props.positiveButtonTitle;
    const { positiveButtonClassName } = this.props;
    const className = positiveButtonClassName || 'button button-positive';
    if (buttonLabel) {
      return (
        <div className={className} onClick={this.handlePositiveClicked}>
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderNegativeButton() {
    const buttonLabel = this.props.negativeButtonTitle;
    const { negativeButtonClassName } = this.props;
    const className = negativeButtonClassName || 'button button-negative';
    if (buttonLabel) {
      return (
        <div className={className} onClick={this.handleNegativeClicked}>
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderNeutralButton() {
    const buttonLabel = this.props.neutralButtonTitle;
    if (buttonLabel) {
      return (
        <div
          className="button button-neutral"
          onClick={this.handleNeutralClicked}
        >
          {buttonLabel}
        </div>
      );
    }
    return null;
  }

  renderButtons() {
    const { ButtonComponent } = this.props;

    if (ButtonComponent) {
      return ButtonComponent;
    }

    return (
      <div className="button-container">
        {this.renderPositiveButton()}
        {this.renderNegativeButton()}
        {this.renderNeutralButton()}
        {this.renderOkButton()}
      </div>
    );
  }

  renderCloseButton() {
    const { disableClose } = this.props;
    if (disableClose) {
      return null;
    }
    return (
      <div className="close-button-container" onClick={this.handleCancel}>
        <div className="close-button" />
      </div>
    );
  }

  render() {
    if (!this.props.visible) {
      return null;
    }

    const { footerClassName = 'footer' } = this.props;

    return (
      <div className="container-overlay">
        <div className="container centered">
          <div className="header">{this.renderTitle()}</div>
          <div className="content">{this.renderMessages()}</div>
          <div className={footerClassName}>
            {!this.props.notShowButton && this.renderButtons()}
          </div>
          {this.renderCloseButton()}
        </div>
      </div>
    );
  }
}

Popup.propTypes = {};

Popup.defaultProps = {};

export default Popup;
