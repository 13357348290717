import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { path } from 'ramda';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import FacebookAnalytics from 'react-facebook-pixel';
import asyncComponent from './hoc/asyncComponent';
import { LoadingSpinner } from './containers/Loading';
import { ErrorPopup } from './containers/Error';
import ChatFeature from './containers/ChatFeature';
import WebTemplateMobileSimulator from '@components/WebTemplateMobileSimulator';
import BottomTabBar from '@components/BottomTabBar';
import Popup from '@components/Popup';
import MessageCustom from '@components/Popup/MessageCustom';
import { getPopups, getMatchDetail, userProfile } from './redux/actions';
import { compose } from 'recompose';
import { withNamespaces } from 'react-i18next';
import { PopupPortal } from '@portals/';
import { GlobalPopup as GlobalPopupConstants } from '@constants/constant';
import ServerPopupContainer from '@containers/ServerPopup';
import DrawerMenu from '@components/DrawerMenu';

const {
  CHANGE_COST_PLAN_DOWNGRADE,
  CHANGE_COST_PLAN_UPGRADE,
  CHANGE_COST_PLAN,
  COMPLETE_UPDATE_PROFILE
} = GlobalPopupConstants;

// Min screen width used to determine whether to render mobile template
const DEVICE_WIDTH = 1224;

const AsyncLoginPage = asyncComponent(() => {
  return import('./containers/LoginPage/Loadable');
});

const AsyncServerRepair = asyncComponent(() => {
  return import('./containers/ServerRepair/Loadable');
});

const AsyncAboutDeactive = asyncComponent(() => {
  return import('./containers/AboutDeactive/Loadable');
});

const AsyncAboutDeactiveApp = asyncComponent(() => {
  return import('./containers/AboutDeactiveApplication/Loadable');
});

const AsyncCurrentUserProfile = asyncComponent(() => {
  return import('./containers/CurrentUserProfile/Loadable');
});

const AsyncLogoutPage = asyncComponent(() => {
  return import('./containers/LogoutPage/Loadable');
});

const AsyncMatchProfile = asyncComponent(() => {
  return import('./containers/MatchProfile/Loadable');
});
const AsyncHomeScreen = asyncComponent(() => {
  return import('./containers/HomeScreen/Loadable');
});
const AsyncParticipation = asyncComponent(() => {
  return import('./containers/Participation/Loadable');
});
const AsyncDatingConfirmation = asyncComponent(() => {
  return import('./containers/DatingConfirmation/Loadable');
});
const AsyncPartnerDetail = asyncComponent(() => {
  return import('./containers/PartnerDetail/Loadable');
});
const AsyncCoupon = asyncComponent(() => {
  return import('./containers/Coupon/Loadable');
});
const AsyncParticipationSteps = asyncComponent(() => {
  return import('@containers/Participation/Steps/Loadable');
});
const AsyncCancelDateApplication = asyncComponent(() => {
  return import('@containers/CancelDateApplication/Loadable');
});

const AsyncHelp = asyncComponent(() => {
  return import('@containers/Help/Loadable');
});
const AsyncContact = asyncComponent(() => {
  return import('@containers/Contact/Loadable');
});
const AsyncAboutCancellation = asyncComponent(() => {
  return import('@components/AboutCancellation/Loadable');
});
const AsyncCancellationApplication = asyncComponent(() => {
  return import('@containers/CancellationApplication/Loadable');
});
const AsyncLightSuggestion = asyncComponent(() => {
  return import('@containers/LightSuggestion/Loadable');
});
const AsyncFeedback = asyncComponent(() => {
  return import('@containers/Feedback/Loadable');
});
const AsyncDowngradeSuggestion = asyncComponent(() => {
  return import('@containers/DowngradeSuggestion/Loadable');
});
const AsyncInviteFriends = asyncComponent(() => {
  return import('@containers/InviteFriends/Loadable');
});
class App extends Component {
  state = {
    isShowSubPopup: 0,
    titleSubPopup: '',
    messageSubPopup: []
  };
  componentDidMount() {
    // Fetch all server popups when App mounts
    if (this.props.loggedIn) {
      this.props.getPopups();

      this.requestUserProfile();
      this.props.getMatchDetail();
    }
  }

  componentDidUpdate(prevProps) {
    // Fetch all server popups after user is logged in
    if (this.props.loggedIn && !prevProps.loggedIn) {
      this.props.getPopups();
    }

    // #ucr7g Render FB pixel for only approved women with face height rating 7 or more.
    if (
      this.props.shouldRenderFbPixelFirstLoginWomanFaceHeight &&
      !prevProps.shouldRenderFbPixelFirstLoginWomanFaceHeight
    ) {
      FacebookAnalytics.init('2244055569238379');
      FacebookAnalytics.pageView();
    }
    // #xg3nu Execute pixel code only when male whose annual income is 10 million or more signs up the very first time
    if (
      this.props.shouldRenderFbPixelFirstLoginMenAnnualIncomeGte10million &&
      !prevProps.shouldRenderFbPixelFirstLoginMenAnnualIncomeGte10million
    ) {
      FacebookAnalytics.init('2491872270836664');
      FacebookAnalytics.pageView();
    }
  }

  requestUserProfile() {
    const lngId = path(
      ['homescreen', 'homescreen', 'data', 'language_id'],
      this.props
    );
    const lngMap = {
      1: 'en/us',
      2: 'ja/jp'
    };
    this.props.userProfile({ body: null, langId: lngMap[lngId] });
  }

  renderServerPopups = popup => {
    switch (popup.type_code) {
      case CHANGE_COST_PLAN_DOWNGRADE:
        return (
          <ServerPopupContainer
            key={popup.id}
            popup={popup}
            type={CHANGE_COST_PLAN_DOWNGRADE}
            onClick={res => {
              var myItems = [];
              var rawItems = res.message.split(/\\n/g);
              for (var i = 0; i < rawItems.length; i++) {
                myItems.push({ content: rawItems[i] });
              }
              this.setState({
                isShowSubPopup: 2,
                titleSubPopup: res.title,
                messageSubPopup: myItems
              });
            }}
          />
        );
      case CHANGE_COST_PLAN:
        return (
          <ServerPopupContainer
            key={popup.id}
            popup={popup}
            type={CHANGE_COST_PLAN}
          />
        );
      case COMPLETE_UPDATE_PROFILE:
        return (
          <ServerPopupContainer
            key={popup.id}
            popup={popup}
            type={COMPLETE_UPDATE_PROFILE}
          />
        );
      case CHANGE_COST_PLAN_UPGRADE:
        return (
          <ServerPopupContainer
            key={popup.id}
            popup={popup}
            type={CHANGE_COST_PLAN_UPGRADE}
            onClick={res => {
              var myItems = [];
              var rawItems = res.message.split(/\\n/g);
              for (var i = 0; i < rawItems.length; i++) {
                myItems.push({ content: rawItems[i] });
              }
              this.setState({
                isShowSubPopup: 1,
                titleSubPopup: res.title,
                messageSubPopup: myItems
              });
            }}
          />
        );
      default:
        return null;
    }
  };

  renderSubPopup = () => {
    const { t } = this.props;
    const { titleSubPopup, messageSubPopup } = this.state;
    switch (this.state.isShowSubPopup) {
      case 1:
        return (
          <Popup
            key="popup-done-changing-btn"
            visible={this.state.isShowSubPopup === 1}
            title={titleSubPopup}
            MessagesComponent={<MessageCustom messages={messageSubPopup} />}
            okButtonTitle={t('popup-done-changing-btn')}
            onOkClicked={() => {
              this.setState({ isShowSubPopup: 0 });
              this.props.history.push('/participation-steps');
            }}
            onCancelClicked={() => {
              this.setState({ isShowSubPopup: 0 });
            }}
          />
        );
      case 2:
        return (
          <Popup
            key="popup-done-resevation"
            visible={this.state.isShowSubPopup === 2}
            title={titleSubPopup}
            MessagesComponent={<MessageCustom messages={messageSubPopup} />}
            okButtonTitle={t('popup-done-resevation-btn')}
            onOkClicked={() => {
              this.setState({ isShowSubPopup: 0 });
            }}
            onCancelClicked={() => {
              this.setState({ isShowSubPopup: 0 });
            }}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const overlay = {
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%',
      zIndex: 50000,
      backgroundColor: 'rgba(0, 0, 0, 1)'
    };
    let routes = (
      <Switch>
        <Route exact path="/" component={AsyncHomeScreen} />
        <Route path="/invite-friends" component={AsyncInviteFriends} />
        <Route path="/feedback" component={AsyncFeedback} />
        <Route path="/chat" component={ChatFeature} />
        <Route path="/coupon" component={AsyncCoupon} />
        <Route path="/participation" component={AsyncParticipation} />
        <Route
          path="/participation-steps"
          component={AsyncParticipationSteps}
        />
        <Route
          path="/cancellation-application"
          component={AsyncCancellationApplication}
        />
        <Route path="/matchprofile" component={AsyncMatchProfile} />
        <Route path="/profile" component={AsyncCurrentUserProfile} />
        <Route path="/partnerdetail" component={AsyncPartnerDetail} />
        <Route exact path="/login" component={AsyncLoginPage} />
        <Redirect to="/" />
      </Switch>
    );

    if (this.props.loggedIn) {
      routes = (
        <Switch>
          <Route path="/feedback" component={AsyncFeedback} />
          <Route path="/invite-friends" component={AsyncInviteFriends} />
          <Route path="/chat" component={ChatFeature} />
          <Route path="/coupon" component={AsyncCoupon} />
          <Route path="/participation" component={AsyncParticipation} />
          <Route
            path="/participation-steps"
            component={AsyncParticipationSteps}
          />
          <Route
            path="/dating-confirmation"
            component={AsyncDatingConfirmation}
          />
          <Route
            path="/cancel-date-application"
            component={AsyncCancelDateApplication}
          />
          <Route path="/matchprofile" component={AsyncMatchProfile} />
          <Route path="/profile" component={AsyncCurrentUserProfile} />
          <Route path="/about-deactivation" component={AsyncAboutDeactive} />
          <Route
            path="/deactivation-application"
            component={AsyncAboutDeactiveApp}
          />
          <Route
            path="/about-cancellation"
            component={AsyncAboutCancellation}
          />
          <Route
            path="/cancellation-application"
            component={AsyncCancellationApplication}
          />
          <Route path="/partnerdetail" component={AsyncPartnerDetail} />
          <Route path="/login" component={AsyncLoginPage} />
          <Route path="/logout" component={AsyncLogoutPage} />
          <Route path="/help" component={AsyncHelp} />
          <Route path="/contact" component={AsyncContact} />
          <Route path="/other" component={AsyncContact} />
          <Route path="/suggestion" component={AsyncLightSuggestion} />
          <Route
            path="/downgrade-suggestion"
            component={AsyncDowngradeSuggestion}
          />
          >
          <Route exact path="/" component={AsyncHomeScreen} />
          <Redirect to="/" />
        </Switch>
      );
    }
    // {this.props.isRepair && <div style={{flex: 1, position: 'absolute', backgroundColor: 'red', zIndex:90000}}></div> }
    return (
      <>
        <MediaQuery minWidth={DEVICE_WIDTH}>
          <WebTemplateMobileSimulator>
            <DrawerMenu>
              {routes}
              <BottomTabBar history={this.props.history} />
              <LoadingSpinner />
              <ErrorPopup />
              {this.props.isRepair && (
                <div style={overlay}>
                  <AsyncServerRepair />
                </div>
              )}

              <PopupPortal>
                {(this.props.popups || []).map(this.renderServerPopups)}
              </PopupPortal>
              {this.state.isShowSubPopup !== 0 ? this.renderSubPopup() : null}
            </DrawerMenu>
          </WebTemplateMobileSimulator>
        </MediaQuery>
        <MediaQuery maxWidth={DEVICE_WIDTH}>
          <DrawerMenu>
            {routes}
            <BottomTabBar history={this.props.history} />
            <LoadingSpinner />
            <ErrorPopup />
            {this.props.isRepair && (
              <div style={overlay}>
                <AsyncServerRepair />
              </div>
            )}

            <PopupPortal>
              {(this.props.popups || []).map(this.renderServerPopups)}
            </PopupPortal>
            {this.state.isShowSubPopup !== 0 ? this.renderSubPopup() : null}
          </DrawerMenu>
        </MediaQuery>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    homescreen: state.homescreen,
    loggedIn: state.auth.loggedIn,
    isRepair: state.servermaintain.isRepair,
    popups: state.popups.data,
    shouldRenderFbPixelFirstLoginWomanFaceHeight: path(
      [
        'auth',
        'userdata',
        'should_render_fb_pixel_first_login_woman_face_height'
      ],
      state
    ),
    shouldRenderFbPixelFirstLoginMenAnnualIncomeGte10million: path(
      [
        'auth',
        'userdata',
        'should_render_fb_pixel_first_login_men_annual_income_gte_10million'
      ],
      state
    )
  };
};

export default compose(
  withNamespaces('common', { wait: true }),
  withRouter,
  connect(
    mapStateToProps,
    { getPopups, userProfile, getMatchDetail }
  )
)(App);
