import { all } from 'redux-saga/effects';
import {
  watchLoginFacebook,
  watchGetFacebook,
  watchLogoutFacebook
} from './authSaga';
import { watchHomeScreen } from './homescreenSaga';
import { watchGetMatchData } from './matchSaga';
import { watchSetParticipation } from './participationSaga';
import { watchGetPopupsData, watchDeletePopupData } from './popupsSaga';
import { watchUserProfile } from './userProfileSaga';
import { watchUseCoupon } from './useCouponSaga';
import { watchCancelDate } from './cancelDateSaga';
import { watchChatRoom } from './chatRoomSaga';
import { watchChatHistory } from './chatHistorySaga';
import { watchGetCafe } from './cafeSaga';
import { watchGetChatMessages } from './chatMessagesSaga';
import { watchDeactiveProfile } from './deactiveProfileSaga';
import {
  watchCancelChangeCostPlan,
  watchChangeCostPlan
} from './changePlanSaga';
import { watchCancelProfile } from './cancelProfileSaga';
import {
  watchSaveUserFeedback,
  watchGetUserFeedbackDetails
} from './feedbackSaga';
import { watchGetInviteFriendsData } from './inviteFriendsSaga';

export default function* rootSaga() {
  yield all([
    watchLoginFacebook(),
    watchLogoutFacebook(),
    watchGetFacebook(),
    watchHomeScreen(),
    watchGetMatchData(),
    watchSetParticipation(),
    watchGetPopupsData(),
    watchDeletePopupData(),
    watchUserProfile(),
    watchUseCoupon(),
    watchCancelDate(),
    watchChatRoom(),
    watchChatHistory(),
    watchGetCafe(),
    watchGetChatMessages(),
    watchCancelChangeCostPlan(),
    watchChangeCostPlan(),
    watchDeactiveProfile(),
    watchCancelProfile(),
    watchSaveUserFeedback(),
    watchGetUserFeedbackDetails(),
    watchGetInviteFriendsData()
  ]);
}
