import React from 'react';
import styles from './index.module.css';
import className from 'classnames';
//
// Shows the chat links that appear in gold below messages.
// Hiển thị các liên kết trò chuyện xuất hiện bằng vàng bên dưới tin nhắn.
// -----------------------
const ChatLinkCustom = ({
  text,
  onChatLinkClick,
  customStyle,
  leftButton,
  rightButton
}) => (
  <span
    className={className(
      customStyle ? styles.chatLinkMaxWidth : styles.chatLinkCustom,
      leftButton ? styles.chatLinkCustomLeft : null,
      rightButton ? styles.chatLinkCustomRight : null
    )}
    onClick={onChatLinkClick}
  >
    <div className={styles.chatLinkTextCustom}>{text}</div>
  </span>
);

export default ChatLinkCustom;
