import React from 'react';
import { Trans } from 'react-i18next';
import styles from './index.module.css';

const TitleComponent = ({ title1, title2 }) => (
  <div className={styles.titleContainer}>
    <div className={styles.title}>{title1}</div>
    <div className={styles.title}>{title2}</div>
  </div>
);

export default TitleComponent;
