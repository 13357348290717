export const CURRENT_USER_LOGIN_FACEBOOK_REQUESTED =
  'CURRENT_USER_LOGIN_FACEBOOK_REQUESTED';
export const CURRENT_USER_LOGIN_FACEBOOK_INFO_REQUESTED =
  'CURRENT_USER_LOGIN_FACEBOOK_INFO_REQUESTED';
export const CURRENT_USER_LOGIN_FACEBOOK_SUCCEEDED =
  'CURRENT_USER_LOGIN_FACEBOOK_SUCCEEDED';
export const CURRENT_USER_LOGIN_FACEBOOK_INFO_SUCCEEDED =
  'CURRENT_USER_LOGIN_FACEBOOK_INFO_SUCCEEDED';
export const CURRENT_USER_LOGIN_FACEBOOK_FAILED =
  'CURRENT_USER_LOGIN_FACEBOOK_FAILED';
export const CURRENT_USER_LOGOUT_FACEBOOK_REQUESTED =
  'CURRENT_USER_LOGOUT_FACEBOOK';
export const CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED =
  'CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED';
export const CURRENT_USER_LOGOUT_FACEBOOK_FAILED =
  'CURRENT_USER_LOGOUT_FACEBOOK_FAILED';

export const HOME_SCREEN_REQUESTED = 'HOME_SCREEN_REQUESTED';
export const HOME_SCREEN_SUCCEEDED = 'HOME_SCREEN_SUCCEEDED';
export const HOME_SCREEN_FAILED = 'HOME_SCREEN_FAILED';
export const CLEAR_HOMESCREEN = 'CLEAR_HOMESCREEN';

export const CURRENT_USER_RESET_MATCH_DATA = 'CURRENT_USER_RESET_MATCH_DATA';
export const CURRENT_USER_GET_MATCH_DATA_REQUESTED =
  'CURRENT_USER_GET_MATCH_DATA_REQUESTED';
export const CURRENT_USER_GET_MATCH_DATA_SUCCEEDED =
  'CURRENT_USER_GET_MATCH_DATA_SUCCEEDED';
export const CURRENT_USER_GET_MATCH_DATA_FAILED =
  'CURRENT_USER_GET_MATCH_DATA_FAILED';
export const PARTNER_REFLECTED_COUPON_POPUP_SHOWN =
  'PARTNER_REFLECTED_COUPON_POPUP_SHOWN';
export const UPDATE_USER_PARTICIPATION_DATES =
  'UPDATE_USER_PARTICIPATION_DATES';

export const USER_PROFILE_REQUESTED = 'USER_PROFILE_REQUESTED';
export const USER_PROFILE_SUCCEEDED = 'USER_PROFILE_SUCCEEDED';
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED';

export const USE_COUPON_REQUESTED = 'USE_COUPON_REQUESTED';
export const USE_COUPON_SUCCEEDED = 'USE_COUPON_SUCCEEDED';
export const USE_COUPON_FAILED = 'USE_COUPON_FAILED';

export const CANCEL_DATE_REQUESTED = 'CANCEL_DATE_REQUESTED';
export const CANCEL_DATE_SUCCEEDED = 'CANCEL_DATE_SUCCEEDED';
export const CANCEL_DATE_FAILED = 'CANCEL_DATE_FAILED';

export const POPUPS_REQUESTED = 'POPUPS_REQUESTED';
export const POPUPS_REQUEST_SUCCEEDED = 'POPUPS_REQUEST_SUCCEEDED';
export const POPUPS_REQUEST_FAILED = 'POPUPS_REQUEST_FAILED';

export const DELETE_POPUP_REQUESTED = 'DELETE_POPUP_REQUESTED';
export const DELETE_POPUP_REQUEST_SUCCEEDED = 'DELETE_POPUP_REQUEST_SUCCEEDED';
export const DELETE_POPUP_REQUEST_FAILED = 'DELETE_POPUP_REQUEST_FAILED';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export const CHAT_ROOM_REQUESTED = 'CHAT_ROOM_REQUESTED';
export const CHAT_ROOM_REQUEST_SUCCEEDED = 'CHAT_ROOM_REQUEST_SUCCEEDED';
export const CHAT_ROOM_REQUEST_FAILED = 'CHAT_ROOM_REQUEST_FAILED';

export const CHAT_HISTORY_REQUESTED = 'CHAT_HISTORY_REQUESTED';
export const CHAT_HISTORY_REQUEST_SUCCEEDED = 'CHAT_HISTORY_REQUEST_SUCCEEDED';
export const CHAT_HISTORY_REQUEST_FAILED = 'CHAT_HISTORY_REQUEST_FAILED';

export const GET_CAFE_REQUESTED = 'GET_CAFE_REQUESTED';
export const GET_CAFE_REQUEST_SUCCEEDED = 'GET_CAFE_REQUEST_SUCCEEDED';
export const GET_CAFE_REQUEST_FAILED = 'GET_CAFE_REQUEST_FAILED';

export const GET_CHAT_MESSAGES_REQUESTED = 'GET_CHAT_MESSAGES_REQUESTED';
export const GET_CHAT_MESSAGES_REQUEST_SUCCEEDED =
  'GET_CHAT_MESSAGES_REQUEST_SUCCEEDED';
export const GET_CHAT_MESSAGES_REQUEST_FAILED =
  'GET_CHAT_MESSAGES_REQUEST_FAILED';

export const SET_PARTICIPATION_REQUESTED = 'SET_PARTICIPATION_REQUESTED';
export const SET_PARTICIPATION_SUCCEEDED = 'SET_PARTICIPATION_SUCCEEDED';
export const SET_PARTICIPATION_FAILED = 'SET_PARTICIPATION_FAILED';

export const SHOW_MAINTAIN = 'SHOW_MAINTAIN';

export const CURRENT_USER_CHANGE_COST_PLAN_REQUESTED =
  'CURRENT_USER_CHANGE_COST_PLAN_REQUESTED';
export const CURRENT_USER_CHANGE_COST_PLAN_SUCCEEDED =
  'CURRENT_USER_CHANGE_COST_PLAN_SUCCEEDED';
export const CURRENT_USER_CHANGE_COST_PLAN_FAILED =
  'CURRENT_USER_CHANGE_COST_PLAN_FAILED';
export const CURRENT_USER_CANCEL_COST_PLAN_REQUESTED =
  'CURRENT_USER_CANCEL_COST_PLAN_REQUESTED';

export const SHOW_TOOLTIPS = 'SHOW_TOOLTIPS';
export const HIDE_TOOLTIPS = 'HIDE_TOOLTIPS';

export const DEACTIVE_PROFILE_REQUESTED = 'DEACTIVE_PROFILE_REQUESTED';
export const DEACTIVE_PROFILE_SUCCEEDED = 'DEACTIVE_PROFILE_SUCCEEDED';
export const DEACTIVE_PROFILE_FAILED = 'DEACTIVE_PROFILE_FAILED';

export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const CANCEL_PROFILE_REQUESTED = 'CANCEL_PROFILE_REQUESTED';
export const CANCEL_PROFILE_SUCCEEDED = 'CANCEL_PROFILE_SUCCEEDED';
export const CANCEL_PROFILE_FAILED = 'CANCEL_PROFILE_FAILED';

export const SAVE_USER_FEEDBACK_REQUESTED = 'SAVE_USER_FEEDBACK_REQUESTED';
export const SAVE_USER_FEEDBACK_SUCCEEDED = 'SAVE_USER_FEEDBACK_SUCCEEDED';
export const SAVE_USER_FEEDBACK_FAILED = 'SAVE_USER_FEEDBACK_FAILED';

export const USER_FEEDBACK_DETAILS_REQUESTED =
  'USER_FEEDBACK_DETAILS_REQUESTED';
export const USER_FEEDBACK_DETAILS_SUCCEEDED =
  'USER_FEEDBACK_DETAILS_SUCCEEDED';
export const USER_FEEDBACK_DETAILS_FAILED = 'USER_FEEDBACK_DETAILS_FAILED';

export const INVITE_FRIENDS_DATA_REQUESTED = 'INVITE_FRIENDS_DATA_REQUESTED';
export const INVITE_FRIENDS_DATA_SUCCEEDED = 'INVITE_FRIENDS_DATA_SUCCEEDED';
export const INVITE_FRIENDS_DATA_FAILED = 'INVITE_FRIENDS_DATA_FAILED';
