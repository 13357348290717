import React from 'react';
import classNames from 'classnames';
import styles from './index.module.css';
import Autolinker from 'autolinker';
//
// Shows partner's text bubble including chat messages from Bachelor. Appears to the left of screen.
// Hiển thị bong bóng văn bản của đối tác bao gồm các tin nhắn trò chuyện từ Cử nhân. Xuất hiện ở bên trái màn hình.
// -----------------------
const PartnerTextMessage = ({ avatar, message, shouldBlurIcon }) => {
  const htmlMessage = Autolinker.link(message, {
    stripPrefix: false
  });

  return (
    <span className={styles.partnerTextMessage}>
      <img
        alt=""
        src={avatar}
        className={classNames({
          [styles.chatUserIcon]: true,
          [styles.blur]: shouldBlurIcon
        })}
      />
      <div
        className={styles.chatPartnerTextBubble}
        dangerouslySetInnerHTML={{ __html: htmlMessage }}
      />
    </span>
  );
};

export default PartnerTextMessage;
