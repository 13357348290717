import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  cafeData: null,
  isRefresh: false
};

const cafeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CAFE_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.GET_CAFE_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        cafeData: action.data,
        error: null
      };
    case types.GET_CAFE_REQUEST_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        cafeData: null,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default cafeReducer;
