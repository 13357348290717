import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '@redux/actions/types';
import { requestAPI } from '@redux/services/request';
import { API } from '@redux/services/api';
import { ResponseStatus } from '@constants/constant';

function* getInviteFriendsData(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.INVITE_FRIENDS,
      API.GET,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.INVITE_FRIENDS_DATA_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      console.error('SAGA:', response.message);
      yield put({ type: types.INVITE_FRIENDS_DATA_FAILED, error: response });
    } else {
      yield put({ type: types.INVITE_FRIENDS_DATA_FAILED, error: response });
    }
  } catch (error) {
    yield put({ type: types.INVITE_FRIENDS_DATA_FAILED, error });
  }
}

export function* watchGetInviteFriendsData() {
  yield takeLatest(types.INVITE_FRIENDS_DATA_REQUESTED, getInviteFriendsData);
}
