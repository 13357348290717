import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  isRefresh: false
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_PROFILE_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.USER_PROFILE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case types.USER_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        isError: true,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default userProfileReducer;
