import {
  either,
  path,
  pickBy,
  pathEq,
  cond,
  always,
  T,
  pipe,
  find,
  last,
  propEq
} from 'ramda';
import defaultConfirmMaleIcon from '@assets/confirm_man.png';
import defaultConfirmFemaleIcon from '@assets/confirm_woman.png';
import { UserGender } from '@constants/constant';

// params: matchData
// returns dating_id: string; or null
export const getSaturdayOrSundayDatingId = either(
  path(['saturday', 'dating_id']),
  path(['sunday', 'dating_id'])
);

// params: dating_id, matchData
// returns { saturday: {...}  } or { sunday: {...} }
export const pickDatingDay = (datingId, matchData) =>
  pickBy(pathEq(['dating_id'], datingId))(matchData);

// param: match.data.(sat|sun).partner_details
// returns: string
export const getPartnerProfileImage = cond([
  [pathEq(['gender'], UserGender.MALE), always(defaultConfirmMaleIcon)],
  [pathEq(['gender'], UserGender.FEMALE), always(defaultConfirmFemaleIcon)],
  [T, always('')] // defaults to empty string
]);

// param: pusher chat room message id, stored chat room messages
// returns: boolean
export const hasChatRoomMessage = (messageId, messages) => {
  const lastMessage = last(messages);
  if (lastMessage) {
    return lastMessage.id >= messageId;
  }
  return false;
};
