import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* getUserFeedbackDetails(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.FEEDBACK_DETAILS,
      API.GET,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.USER_FEEDBACK_DETAILS_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      console.error('SAGA:', response.message);
      yield put({ type: types.USER_FEEDBACK_DETAILS_FAILED, error: response });
    } else {
      yield put({ type: types.USER_FEEDBACK_DETAILS_FAILED, error: response });
    }
  } catch (error) {
    yield put({ type: types.USER_FEEDBACK_DETAILS_FAILED, error });
  }
}

function* saveUserFeedback(action) {
  let response = null;
  try {
    response = yield call(requestAPI, API.FEEDBACK, API.POST, action.params);
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.SAVE_USER_FEEDBACK_SUCCEEDED
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      console.error('SAGA:', response.message);
      yield put({ type: types.SAVE_USER_FEEDBACK_FAILED, error: response });
    } else {
      yield put({ type: types.SAVE_USER_FEEDBACK_FAILED, error: response });
    }
  } catch (error) {
    yield put({ type: types.SAVE_USER_FEEDBACK_FAILED, error });
  }
}

export function* watchSaveUserFeedback() {
  yield takeLatest(types.SAVE_USER_FEEDBACK_REQUESTED, saveUserFeedback);
}

export function* watchGetUserFeedbackDetails() {
  yield takeLatest(
    types.USER_FEEDBACK_DETAILS_REQUESTED,
    getUserFeedbackDetails
  );
}
