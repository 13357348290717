import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isRefresh: false,
  isError: false,
  error: null
};

const participationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PARTICIPATION_REQUESTED:
      return { ...state, isLoading: true, isError: false, error: null };
    case types.SET_PARTICIPATION_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        error: null
      };
    case types.SET_PARTICIPATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false,
        error: null
      };
    default:
      return state;
  }
};

export default participationReducer;
