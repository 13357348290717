import axios from 'axios';
import { showMaintain } from '../actions/index';
import * as api from './api';
import { store } from '../store';
import { initializeReducers } from 'initializable-reducer';

import { getUserLanguage } from '../../helper/LanguageHelper';
import { redirectFB } from '@constants/constant';

const defaultNetworkError = {
  meta: {
    status: 0,
    msg:
      'An error occurred while processing your request. Please try again later.'
  },
  response: null
};

const defaultInvalidToken = {
  meta: {
    status: 401,
    msg: 'Login session expired. Please login again.'
  },
  response: null
};

export default function request(options, type) {
  return axios(options);
}

function* requestAPI(path, method, params = {}, timeout = 30000) {
  let baseURL = api.API.BASE_URL;
  let additionPath = path;
  let accessToken = yield store.getState().auth.userdata.token
    ? store.getState().auth.userdata.token.access_token
    : '';
  const lngMap = {
    1: 'en/us',
    2: 'ja/jp'
  };
  const languageId = getUserLanguage();
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept-Language': params.langId || lngMap[languageId]
  };

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`
    };
  }

  let response = null;
  try {
    response = yield axios.request({
      url: additionPath,
      method: method,
      baseURL: baseURL,
      headers: headers,
      data: method === api.API.POST ? params.body : null,
      params: params.query,
      timeout: timeout
    });
    // console.log(method, baseURL + "/" + additionPath, params);
    if (response.status === 401) {
      yield store.dispatch(initializeReducers());
      yield localStorage.setItem(
        'redirect_to',
        window.location.pathname.startsWith('/login')
          ? ''
          : window.location.pathname
      );
      // yield redirectFB();
      return response.data;
    } else if (response.status === 20) {
      yield store.dispatch(showMaintain());
      return response.data;
    }
    return response.data;
  } catch (e) {
    if (e.response.status === 503) {
      yield store.dispatch(showMaintain());
      return e;
    }
    yield store.dispatch(initializeReducers());
    yield localStorage.setItem(
      'redirect_to',
      window.location.pathname.startsWith('/login')
        ? ''
        : window.location.pathname
    );
    // yield redirectFB();
    return e;
  }
}

export { requestAPI };
