import * as types from './types';

export function loginFacebook(params, onComplete) {
  return {
    type: types.CURRENT_USER_LOGIN_FACEBOOK_REQUESTED,
    params,
    onComplete
  };
}

export function logoutFacebook(params, onComplete) {
  return {
    type: types.CURRENT_USER_LOGOUT_FACEBOOK_REQUESTED,
    params,
    onComplete
  };
}

export function getFacebook(params, onComplete) {
  return {
    type: types.CURRENT_USER_LOGIN_FACEBOOK_INFO_REQUESTED,
    params,
    onComplete
  };
}

export function homescreen(params, onComplete) {
  return {
    type: types.HOME_SCREEN_REQUESTED,
    params,
    onComplete
  };
}

export function clearHomescreen(params, onComplete) {
  return {
    type: types.CLEAR_HOMESCREEN,
    params,
    onComplete
  };
}

export function getMatchDetail(params, onComplete) {
  return {
    type: types.CURRENT_USER_GET_MATCH_DATA_REQUESTED,
    params,
    onComplete
  };
}
export function getChangeCostPlan(params, onComplete) {
  return {
    type: types.CURRENT_USER_CHANGE_COST_PLAN_REQUESTED,
    params,
    onComplete
  };
}

export function getCancelChangeCostPlan(params, onComplete) {
  return {
    type: types.CURRENT_USER_CANCEL_COST_PLAN_REQUESTED,
    params,
    onComplete
  };
}

export function resetMatchDetail() {
  return {
    type: types.CURRENT_USER_RESET_MATCH_DATA
  };
}

export function getPopups(params, onComplete) {
  return {
    type: types.POPUPS_REQUESTED,
    params,
    onComplete
  };
}

export function deletePopup(params, onComplete) {
  return {
    type: types.DELETE_POPUP_REQUESTED,
    params,
    onComplete
  };
}

export function partnerReflectedByCouponPopupShown() {
  return {
    type: types.PARTNER_REFLECTED_COUPON_POPUP_SHOWN
  };
}

export function userProfile(params, onComplete) {
  return {
    type: types.USER_PROFILE_REQUESTED,
    params,
    onComplete
  };
}

export function useCoupon(params, onComplete) {
  return {
    type: types.USE_COUPON_REQUESTED,
    params,
    onComplete
  };
}

export function cancelDate(params, onComplete) {
  return {
    type: types.CANCEL_DATE_REQUESTED,
    params,
    onComplete
  };
}

export function chatRoom(params, onComplete) {
  return {
    type: types.CHAT_ROOM_REQUESTED,
    params,
    onComplete
  };
}

export function chatHistory(params, onComplete) {
  return {
    type: types.CHAT_HISTORY_REQUESTED,
    params,
    onComplete
  };
}

export function getCafe(params, onComplete) {
  return {
    type: types.GET_CAFE_REQUESTED,
    params,
    onComplete
  };
}

export function getChatMessages(params, onComplete) {
  return {
    type: types.GET_CHAT_MESSAGES_REQUESTED,
    params,
    onComplete
  };
}

export function updateUserParticipationDates(dates) {
  return {
    type: types.UPDATE_USER_PARTICIPATION_DATES,
    dates
  };
}

export function showMaintain() {
  return {
    type: types.SHOW_MAINTAIN
  };
}

export function showLoading() {
  return {
    type: types.SHOW_LOADING
  };
}

export function hideLoading() {
  return {
    type: types.HIDE_LOADING
  };
}
export function showTooltip() {
  return {
    type: types.SHOW_TOOLTIPS
  };
}

export function hideTooltip() {
  return {
    type: types.HIDE_TOOLTIPS
  };
}

export function showError() {
  return {
    type: types.SHOW_ERROR
  };
}

export function hideError() {
  return {
    type: types.HIDE_ERROR
  };
}

export function setParticipation(params, onComplete) {
  return {
    type: types.SET_PARTICIPATION_REQUESTED,
    params,
    onComplete
  };
}

export function requestDeactiveProfile(params, onComplete) {
  return {
    type: types.DEACTIVE_PROFILE_REQUESTED,
    params,
    onComplete
  };
}

export function openDrawer() {
  return {
    type: types.OPEN_DRAWER
  };
}

export function closeDrawer() {
  return {
    type: types.CLOSE_DRAWER
  };
}

export function requestCancelProfile(params, onComplete) {
  return {
    type: types.CANCEL_PROFILE_REQUESTED,
    params,
    onComplete
  };
}

export function requestSaveUserFeedback(params, onComplete) {
  return {
    type: types.SAVE_USER_FEEDBACK_REQUESTED,
    params,
    onComplete
  };
}

export function requestUserFeedbackDetails(params, onComplete) {
  return {
    type: types.USER_FEEDBACK_DETAILS_REQUESTED,
    params,
    onComplete
  };
}

export function requestInviteFriendsData(params, onComplete) {
  return {
    type: types.INVITE_FRIENDS_DATA_REQUESTED,
    params,
    onComplete
  };
}
