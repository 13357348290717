import React, { Component } from 'react';
import DefaultPopup from '../DefaultPopup';

class ChangePlanSucess extends Component {
  render() {
    const { popup } = this.props;

    return (
      <DefaultPopup
        popup={popup}
        onButtonClick={this.props.onButtonClick}
        onCloseClick={this.props.onCloseClick}
      />
    );
  }
}

export default ChangePlanSucess;
