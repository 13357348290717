import * as types from '../actions/types';

const initialState = {
  isLoading: false,
  isError: false,
  data: {}
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_USER_FEEDBACK_REQUESTED:
    case types.USER_FEEDBACK_DETAILS_REQUESTED:
      return { ...state, isLoading: true, error: null };
    case types.SAVE_USER_FEEDBACK_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.USER_FEEDBACK_DETAILS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.data || {}
      };
    case types.SAVE_USER_FEEDBACK_FAILED:
    case types.USER_FEEDBACK_DETAILS_FAILED:
      return {
        ...state,
        isError: true,
        isLoading: false,
        error: action.error
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    default:
      return state;
  }
};

export default feedbackReducer;
