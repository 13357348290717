import * as types from '../actions/types';
import { enableInitializing } from 'initializable-reducer';
import { pathOr } from 'ramda';
const INITIAL = {
  first: false, // true no sync await
  loggedIn: false,
  userdata: {},
  isLoading: false,
  isError: false
};

const authReducer = enableInitializing((state = INITIAL, action) => {
  switch (action.type) {
    case types.CURRENT_USER_LOGIN_FACEBOOK_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.CURRENT_USER_LOGIN_FACEBOOK_SUCCEEDED:
      return {
        ...state,
        loggedIn: true,
        userdata: action.data || {},
        isLoading: false
      };
    case types.CURRENT_USER_LOGIN_FACEBOOK_INFO_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.CURRENT_USER_LOGIN_FACEBOOK_INFO_SUCCEEDED:
      return {
        ...state,
        isLoading: false
      };
    case types.CURRENT_USER_LOGIN_FACEBOOK_FAILED:
      return {
        ...state,
        isError: true,
        loggedIn: false,
        userdata: {},
        error: action.error,
        isLoading: false
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    case types.CURRENT_USER_LOGOUT_FACEBOOK_REQUESTED:
      return {
        ...state,
        isLoading: true
      };
    case types.CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        userdata: {},
        loggedIn: false,
        error: null,
        isError: false
      };
    case types.CURRENT_USER_LOGOUT_FACEBOOK_FAILED:
      return {
        ...state,
        isError: true,
        loggedIn: false,
        userdata: {},
        error: action.error,
        isLoading: false
      };
    case types.HIDE_ERROR:
      return {
        ...state,
        isError: false
      };
    case 'persist/REHYDRATE':
      return {
        ...state,
        ...pathOr({}, ['payload', 'auth'], action),
        userdata: {
          ...pathOr({}, ['payload', 'auth', 'userdata'], action),
          should_render_fb_pixel_first_login_woman_face_height: false,
          should_render_fb_pixel_first_login_men_annual_income_gte_10million: false
        }
      };
    default:
      return state;
  }
});

export default authReducer;
