import { API } from '../redux/services/api';

export const HttpCode = {
  SUCEESS: 200,
  UNAUTHORIZED: 401
};

export const ResponseStatus = {
  FAILURE: 0,
  SUCCESS: 1,
  FORCEFULLY: 2,
  NEW_USER: 3,
  DEACTIVE_USER: 4,
  PROFILE_AWAITING_USER: 5,
  INCOMPLETE_PROFILE_USER: 6,
  DOCCUMENT_REQUIRED: 7,
  CANCEL_USER: 8,
  TEMPORARY_CANCEL: 9,
  PERMANENT_CANCEL: 10,
  LOCK_USER: 11,
  VALIDATION_ERROR: 101,
  FEEDBACK_VIEW: 301,
  COUPON_VALIDATION_UNPARTICIPATION: 12
};

export const UserStatus = {
  INCOMPLETE_PROFILE: 1,
  AWAITING_SCREENING: 2,
  VERIFIED_BY_ADMIN: 3,
  APPROVED_BY_SYSTEM: 4,
  ADMIN_DEACTIVE: 5,
  CANCELLED_PROFILE: 6,
  LOCKED_PROFILE: 7
};

export const ParticipationStatus = {
  MATCH: 3,
  ALGORYTHAM: 2
};

export const AsyncKeys = {
  USER_DATA: 'user_data',
  USER_ID: 'USER_ID',
  USER_NAME: 'USER_NAME',
  USER_EMAIL: 'USER_EMAIL',
  USER_PIC: 'USER_PIC',
  USER_PREFRENCE: 'USER_PREFRENCE',
  USER_ACCESS_TOKEN: 'USER_ACCESS_TOKEN',
  USER_REFRESH_TOKEN: 'USER_REFRESH_TOKEN',
  USER_GENDER: 'USER_GENDER',
  USER_LANGUAGE: 'USER_LANGUAGE',
  USER_PROFILE_IMAGE: 'USER_ROFILE_IMAGE',
  MATCH_PROFILE_DATA: 'MATCH_PROFILE_DATA',
  POPOUP_GLOBAL: 'POPOUP_GLOBAL',
  INTRO_SCREEN: 'INTRO_SCREEN',
  FIRST_TIME_CHAT_SATURDAY: 'FIRST_TIME_CHAT_SATURDAY',
  FIRST_TIME_CHAT_SUNDAY: 'FIRST_TIME_CHAT_SUNDAY',
  USER_SOCIAL_LOGIN_URL: 'USER_SOCIAL_LOGIN_URL',
  COUPON_COUNT: 'COUPON_COUNT',
  COUPON_EXPIRE_AT: 'COUPON_EXPIRE_AT'
};

export const WeekPlan = {
  WEEKDAY: 'weekday',
  WEEKEND: 'weekend'
};

export const CostPlan = {
  LIGHT: 'light',
  NORMAL: 'normal',
  PREMIUM: 'premium'
};

export const DatingEvent = {
  CANCELLED: 1,
  LOCATION_CHANGED: 2
};

export const CouponStatus = {
  READY_TO_USE_COUPON: 0,
  COUPON_NOT_AVAILABLE: 1,
  COUPON_IN_USE: 2
};

export const UserGender = {
  MALE: 1,
  FEMALE: 2
};

export const MatchDetails = {
  STATUS_NO_ANY_DATE: 0,
  STATUS_DATING_MATCHED_PENDING: 1,
  STATUS_DATING_MATCHED_CONFIRM: 2,
  STATUS_DATING_MATCHED_CANCELLED: 3,
  STATUS_DATING_MATCHED_COMPLETED: 4
};

export const MatchProfile = {
  POPUP_PARTNER_NOT_FOUND: 1,
  POPUP_HOW_TO_BE_MATCHED: 2,
  POPUP_DATE_DECIDED: 3,
  POPUP_PARTNER_CANCELLED: 4,
  POPUP_PARTNER_CHANGED_LOCATION: 5
};

export const GlobalPopup = {
  CHANGE_COST_PLAN: 6,
  CHANGE_COST_PLAN_DOWNGRADE: 7,
  CHANGE_COST_PLAN_UPGRADE: 8,
  COMPLETE_UPDATE_PROFILE: 9
};

export const ChatFeature = {
  USER_TYPE_BASE_USER: 'baseUser',
  USER_TYPE_PARTNER_USER: 'partnerUser',
  USER_TYPE_CAFE_INFO: 'cafeInfo',
  USER_TYPE_CAFE_SUGGESTION: 'cafeSuggestion',
  USER_TYPE_CAFE_SUGGESTION_LINK: 'cafeSuggestionLink'
};

export const redirectFB = () => {
  window.location.replace(API.BASE_URL + 'social-login?redirect_url=app-login');
};
export const redirectLINELogin = nextAction => {
  window.location.replace(
    API.BASE_URL +
      `social-login?redirect_url=app-line-login${
        nextAction ? '&action=' + nextAction : ''
      }`
  );
};
export const redirectFacebookLogin = nextAction => {
  window.location.replace(
    API.BASE_URL +
      `social-login?redirect_url=app-login${
        nextAction ? '&action=' + nextAction : ''
      }`
  );
};
export const redirectLINE = (socialLoginLink, userId) => {
  window.location.replace(
    `${socialLoginLink}&id=${userId}&redirect_url=${encodeURIComponent(
      API.BASE_URL + 'social-login?redirect_url=line-login'
    )}`
  );
};

export const redirectUnderScreeningMaleUser = () => {
  window.location.replace('https://www.bachelorapp.net/usc-male');
};

export const redirectUnderScreeningFemaleUser = () => {
  window.location.replace('https://www.bachelorapp.net/usc-female');
};
