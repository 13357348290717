import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* chatRoom(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.GET_CHAT_ROOM_V2,
      API.GET,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CHAT_ROOM_REQUEST_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      console.error('SAGA:', response.message);
      yield put({ type: types.CHAT_ROOM_REQUEST_FAILED, error: response });
    } else {
      yield put({ type: types.CHAT_ROOM_REQUEST_FAILED, error: response });
    }
  } catch (error) {
    yield put({ type: types.CHAT_ROOM_REQUEST_FAILED, error });
  }
}

export function* watchChatRoom() {
  yield takeLatest(types.CHAT_ROOM_REQUESTED, chatRoom);
}
