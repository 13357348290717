import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../actions/types';
import { requestAPI } from '../services/request';
import { API } from '../services/api';
import { ResponseStatus } from '../../constants/constant';

function* getMatchData(action) {
  let response = null;
  try {
    response = yield call(
      requestAPI,
      API.MATCH_DETAIL,
      API.POST,
      action.params
    );
    if (action.onComplete) {
      action.onComplete(response);
    }

    if (response.status === ResponseStatus.SUCCESS) {
      yield put({
        type: types.CURRENT_USER_GET_MATCH_DATA_SUCCEEDED,
        data: response.data
      });
    } else if (response.status === ResponseStatus.FAILURE) {
      console.error('SAGA:', response.message);
      yield put({
        type: types.CURRENT_USER_GET_MATCH_DATA_FAILED,
        error: response
      });
    } else {
      yield put({
        type: types.CURRENT_USER_GET_MATCH_DATA_FAILED,
        error: response
      });
    }
  } catch (error) {
    yield put({ type: types.CURRENT_USER_GET_MATCH_DATA_FAILED, error });
  }
}

export function* watchGetMatchData() {
  yield takeLatest(types.CURRENT_USER_GET_MATCH_DATA_REQUESTED, getMatchData);
}
